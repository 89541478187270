import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../../assets/images/logo_fasterclass.png'
import './Header.scss'
import { Download, FileText, HelpCircle, Home, Menu, MessageCircle, User, X } from 'react-feather'
import NavMenu from './NavMenu'
import Popup from 'reactjs-popup'
import { IPremiumCompanyObject } from '../../../../store/user/reducer'
import imageResizer from '../../../../tools/imageResizer'
import i18n from '../../../../tools/i18n'
import Button from '../../../../components/Button'
import { ICohortObject } from '../../../../store/cohorts/reducer'
import Intercom from '../../../../tools/intercom'

interface IProps {
  lang: 'en' | 'fr'
  cohort: ICohortObject | undefined
  history: any
}
interface IState {
  menuOpened: boolean
  sponsorOpened: boolean
}

class Header extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = { menuOpened: false, sponsorOpened: false }
  }
  componentDidMount() {
    //document.addEventListener('mousedown', this.closeMenu, true)
  }
  componentWillUnmount() {
    //document.removeEventListener('mousedown', this.closeMenu, true)
  }
  private closeMenu = () => {
    if (this.state.menuOpened || this.state.sponsorOpened) {
      setTimeout(() => this.setState({ menuOpened: false, sponsorOpened: false }), 200)
    }
  }
  public render(): JSX.Element {
    const { cohort, lang } = this.props
    if (!cohort || !cohort.subject) return <div />
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          height: '100vh',
          width: '100%',
        }}
      >
        {this.props.children}
        <div className="headerscorm">
          <div className="headerscorm__container">
            <div className="headerscorm__logo-box">
              <img src={Logo} alt="logo" className="headerscorm__logo" />
              <span className="headerscorm__logo-boxtext">
                {lang === 'fr' ? cohort.subject.titre : cohort.subject.titreEn}
              </span>
            </div>

            <div className="headerscorm__options" id={'chatlink'}>
              <Button
                onClick={this.goHome}
                theme="greyLight"
                style={{
                  justifyContent: 'center',
                  marginRight: 15,
                  height: 52,
                  width: 52,
                  padding: 0,
                  borderRadius: '50%',
                  textAlign: 'center',
                  display: 'flex',
                  marginLeft: 15,
                }}
              >
                <Home size={28} style={{ marginRight: 0 }} />
              </Button>
              <Button
                onClick={this.toggleMenu}
                theme="greyLight"
                style={{
                  justifyContent: 'center',
                  marginRight: 85,
                  height: 52,
                  width: 52,
                  padding: 0,
                  borderRadius: '50%',
                  textAlign: 'center',
                  display: 'flex',
                }}
              >
                <FileText size={28} style={{ marginRight: 0 }} />
              </Button>
            </div>
          </div>
        </div>

        <div
          className="headerscorm__sideBarContainer"
          style={this.state.menuOpened ? { display: 'flex' } : { display: 'none' }}
        >
          <div className="headerscorm__sideBarGrey" onClick={this.toggleMenu}></div>
          <div className="headerscorm__sideBar">
            <div className="headerscorm__sideBarMenu">
              <div className="headerscorm__sideBarMenuButton">
                <Button onClick={this.toggleMenu} theme="greyLight">
                  <X size={24} />
                  {i18n.t('pages.online.scormreader.menu.close')}
                </Button>
              </div>
              <div className="headerscorm__sideBarMenuList">
                {cohort.ressources.map((ressource) => (
                  <div className="headerscorm__sideRessourceContainer">
                    <div className="headerscorm__sideRessourceImg">
                      <img src={ressource.image} />
                    </div>
                    <div className="headerscorm__sideRessourceText">
                      {lang === 'fr' ? ressource.title : ressource.fileEn}
                    </div>
                    <div className="headerscorm__sideRessourceBtn">
                      <Button
                        onClick={() => this.openRessource(lang === 'fr' ? ressource.file : ressource.fileEn)}
                        theme="primary"
                        style={{
                          justifyContent: 'center',
                          marginRight: 85,
                          height: 40,
                          width: 40,
                          padding: 0,
                          borderRadius: '50%',
                          textAlign: 'center',
                          display: 'flex',
                        }}
                      >
                        <Download size={24} style={{ marginRight: 0 }} />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  private toggleMenu = () => {
    this.setState({ menuOpened: !this.state.menuOpened })
  }
  private goHome = () => {
    this.props.history.replace('/online/')
  }
  private openRessource = (url: string) => {
    window.open(url, '_blank', 'noreferrer')
  }
}

export default Header
