import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import {
  ADMINCOHORTS_FETCH,
  ADMINCOHORTS_FETCH_SUCCESS,
  ADMINCOHORTS_FETCH_REQUESTING,
  ADMINCOHORTS_FETCH_ERROR,
  ADMINCOHORT_FETCH,
  ADMINCOHORT_FETCH_SUCCESS,
  ADMINCOHORT_FETCH_REQUESTING,
  ADMINCOHORT_FETCH_ERROR,
  ADMIN_FETCHSUBJECTS,
  ADMIN_FETCHSUBJECTS_REQUESTING,
  ADMIN_FETCHSUBJECTS_ERROR,
  ADMIN_FETCHSUBJECTS_SUCCESS,
  ADMIN_DELETE_RESSOURCE,
  ADMIN_DELETE_RESSOURCE_SUCCESS,
  ADMIN_FETCHAPPRENANTS,
  ADMIN_FETCHAPPRENANTS_REQUESTING,
  ADMIN_FETCHAPPRENANTS_ERROR,
  ADMIN_FETCHAPPRENANTS_SUCCESS,
  ADMIN_DELETE_PARTICIPATE_SUCCESS,
  ADMIN_DELETE_PARTICIPATE,
  ADMIN_FETCH_APPRENANT,
  ADMIN_FETCH_APPRENANT_REQUESTING,
  ADMIN_FETCH_APPRENANT_ERROR,
  ADMIN_FETCH_APPRENANT_SUCCESS,
  ADMIN_DELETE_PARTICIPATE_USER,
  ADMIN_DELETE_PARTICIPATE_USER_SUCCESS,
  ADMIN_FETCHSCORMS,
  ADMIN_FETCHSCORM,
  ADMIN_FETCHSCORMS_REQUESTING,
  ADMIN_FETCHSCORMS_ERROR,
  ADMIN_FETCHSCORMS_SUCCESS,
  ADMIN_FETCHSCORM_REQUESTING,
  ADMIN_FETCHSCORM_ERROR,
  ADMIN_FETCHSCORM_SUCCESS,
} from './constants'
import {
  cohortsFetchAction,
  cohortProgressAction,
  deleteRessourceAction,
  cohortFetchAction,
  subjectsFetchAction,
  apprenantsFetchAction,
  deleteParticipateAction,
  subjectFetchAction,
  apprenantDetailAction,
  scormFetchAction,
  scormsFetchAction,
} from './actions'
import { IAdminCohortLightObject, IScormAdminObject, IScormDetailsAdminObject } from './reducer'
import { IStore } from '..'
import { ACTIONS_FETCH } from '../actions/constants'

export default function* adminWatcher(): IterableIterator<any> {
  yield takeLatest([ADMINCOHORTS_FETCH], cohortsFetch)
  yield takeLatest([ADMIN_FETCHSUBJECTS], subjectsFetch)
  yield takeLatest([ADMIN_DELETE_RESSOURCE], deleteRessource)
  yield takeLatest([ADMIN_DELETE_PARTICIPATE], deleteParticipate)
  yield takeLatest([ADMIN_DELETE_PARTICIPATE_USER], deleteParticipateUser)
  yield takeLatest([ADMIN_FETCHAPPRENANTS], apprenantsFetch)
  yield takeLatest([ADMIN_FETCH_APPRENANT], apprenantFetch)
  yield takeLatest([ADMIN_FETCHSCORMS], scormsFetch)
  yield takeLatest([ADMIN_FETCHSCORM], scormFetch)
  return yield takeLatest([ADMINCOHORT_FETCH], cohortFetch)
}

function* cohortsFetch({ payload }: any): any {
  yield put({ type: ADMINCOHORTS_FETCH_REQUESTING })
  const data: IAdminCohortLightObject[] | false = yield call(cohortsFetchAction, payload)
  if (!data) {
    return yield put({
      type: ADMINCOHORTS_FETCH_ERROR,
    })
  }
  return yield put({
    type: ADMINCOHORTS_FETCH_SUCCESS,
    payload: data,
  })
}
function* scormsFetch({ payload }: any): any {
  yield put({ type: ADMIN_FETCHSCORMS_REQUESTING })
  const data: IScormAdminObject[] | false = yield call(scormsFetchAction, payload)
  if (!data) {
    return yield put({
      type: ADMIN_FETCHSCORMS_ERROR,
    })
  }
  return yield put({
    type: ADMIN_FETCHSCORMS_SUCCESS,
    payload: data,
  })
}
function* scormFetch({ payload }: any): any {
  yield put({ type: ADMIN_FETCHSCORM_REQUESTING })
  const data: IScormDetailsAdminObject[] | false = yield call(scormFetchAction, payload)
  if (!data) {
    return yield put({
      type: ADMIN_FETCHSCORM_ERROR,
    })
  }
  return yield put({
    type: ADMIN_FETCHSCORM_SUCCESS,
    payload: data,
  })
}
function* cohortFetch({ payload }: any): any {
  yield put({ type: ADMINCOHORT_FETCH_REQUESTING })

  const data: IAdminCohortLightObject[] | false = yield call(cohortFetchAction, payload)
  if (!data) {
    return yield put({
      type: ADMINCOHORT_FETCH_ERROR,
    })
  }
  return yield put({
    type: ADMINCOHORT_FETCH_SUCCESS,
    payload: data,
  })
}
function* subjectsFetch({ payload }: any): any {
  yield put({ type: ADMIN_FETCHSUBJECTS_REQUESTING })
  const data: IAdminCohortLightObject[] | false = yield call(subjectsFetchAction, payload)
  if (!data) {
    return yield put({
      type: ADMIN_FETCHSUBJECTS_ERROR,
    })
  }
  return yield put({
    type: ADMIN_FETCHSUBJECTS_SUCCESS,
    payload: data,
  })
}
function* apprenantFetch({ payload }: any): any {
  yield put({ type: ADMIN_FETCH_APPRENANT_REQUESTING })
  const data: IAdminCohortLightObject[] | false = yield call(apprenantDetailAction, payload)
  if (!data) {
    return yield put({
      type: ADMIN_FETCH_APPRENANT_ERROR,
    })
  }
  return yield put({
    type: ADMIN_FETCH_APPRENANT_SUCCESS,
    payload: data,
  })
}
function* apprenantsFetch({ payload }: any): any {
  yield put({ type: ADMIN_FETCHAPPRENANTS_REQUESTING })
  const data: IAdminCohortLightObject[] | false = yield call(apprenantsFetchAction, payload)
  if (!data) {
    return yield put({
      type: ADMIN_FETCHAPPRENANTS_ERROR,
    })
  }
  return yield put({
    type: ADMIN_FETCHAPPRENANTS_SUCCESS,
    payload: data,
  })
}
function* deleteRessource({ payload }: any): any {
  const data: IAdminCohortLightObject[] | false = yield call(deleteRessourceAction, payload)

  return yield put({
    type: ADMIN_DELETE_RESSOURCE_SUCCESS,
    payload: payload,
  })
}
function* deleteParticipate({ payload }: any): any {
  const data: IAdminCohortLightObject[] | false = yield call(deleteParticipateAction, payload)

  return yield put({
    type: ADMIN_DELETE_PARTICIPATE_SUCCESS,
    payload: payload.participateId,
  })
}
function* deleteParticipateUser({ payload }: any): any {
  const data: IAdminCohortLightObject[] | false = yield call(deleteParticipateAction, payload)

  return yield put({
    type: ADMIN_DELETE_PARTICIPATE_USER_SUCCESS,
    payload: payload.participateId,
  })
}
