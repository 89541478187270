import React, { ChangeEvent, CSSProperties } from 'react'
import { IStore } from '../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR } from '../../store/user/constants'
import { IAdminCohortObject, IAdminCohortLightObject, IUserAdminObject } from '../../store/admin/reducer'
import {
  ADMINCOHORTS_EDITSUCCESS,
  ADMINCOHORTS_FETCH,
  ADMIN_FETCHAPPRENANTS,
  ADMIN_FETCHSUBJECTS,
} from '../../store/admin/constants'

import Select from 'react-select'

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void

  cohorts: IAdminCohortLightObject[]

  value: any
  onChange: (name: string, value: string) => void
  name: string
  style?: CSSProperties
  containerStyle?: CSSProperties
  placeholder?: string
  locked?: boolean
  apprenant?: IUserAdminObject
}

interface IState {}
class CohortSelect extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
    this.props.dispatch({ type: LOGIN_ERROR, payload: '' })
  }
  async componentDidMount() {
    this.props.dispatch({ type: ADMINCOHORTS_FETCH, payload: { apprenantId: this.props.apprenant?.id } })
  }

  public render(): JSX.Element | null {
    return (
      <div style={{ ...this.props.containerStyle }}>
        <Select
          name="cohorts-select"
          className="react-select-container"
          classNamePrefix="react-select"
          options={this.props.cohorts}
          getOptionLabel={(option: IAdminCohortLightObject) =>
            `${option.label} - ${option.subject.titre} [${option.id}]`
          }
          onChange={this.onChange}
          isOptionSelected={(option: IAdminCohortLightObject, selected: any) => option.id === selected.id}
        />
      </div>
    )
  }

  onChange = (newValue: any) => {
    this.props.onChange(this.props.name, newValue)
  }
}

export default connect((store: IStore) => ({
  loading: store.admin.subjects.requesting,
  error: store.admin.subjects.error,
  cohorts: store.admin.cohorts.datas,
  apprenant: store.admin.apprenant.datas,
}))(CohortSelect)
