import React, { ChangeEvent, CSSProperties } from 'react'
import { IStore } from '../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR } from '../../store/user/constants'
import { IAdminCohortObject, IApprenantAdminObject, IUserAdminObject } from '../../store/admin/reducer'
import {
  ADMINCOHORTS_EDITSUCCESS,
  ADMINCOHORTS_FETCH,
  ADMIN_FETCHAPPRENANTS,
  ADMIN_FETCHSUBJECTS,
} from '../../store/admin/constants'

import Select from 'react-select'

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void

  apprenants: IUserAdminObject[]

  value: any
  onChange: (name: string, value: string) => void
  name: string
  style?: CSSProperties
  containerStyle?: CSSProperties
  placeholder?: string
  locked?: boolean
  cohort?: IAdminCohortObject
}

interface IState {}
class ApprenantSelect extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
    this.props.dispatch({ type: LOGIN_ERROR, payload: '' })
  }
  async componentDidMount() {
    this.props.dispatch({ type: ADMIN_FETCHAPPRENANTS, payload: { cohortId: this.props.cohort?.id } })
  }

  public render(): JSX.Element | null {
    return (
      <div style={{ ...this.props.containerStyle }}>
        <Select
          name="subjects-select"
          className="react-select-container"
          classNamePrefix="react-select"
          options={this.props.apprenants}
          getOptionLabel={(option: IUserAdminObject) => `${option.lastname} ${option.firstname} [${option.id}]`}
          onChange={this.onChange}
          isOptionSelected={(option: IUserAdminObject, selected: any) => option.id === selected.id}
        />
      </div>
    )
  }

  onChange = (newValue: any) => {
    this.props.onChange(this.props.name, newValue)
  }
}

export default connect((store: IStore) => ({
  loading: store.admin.subjects.requesting,
  error: store.admin.subjects.error,
  apprenants: store.admin.apprenants.datas,
  cohort: store.admin.cohort.datas,
}))(ApprenantSelect)
