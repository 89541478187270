import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR } from '../../../store/user/constants'
import theme from '../../../theme'
import API from '../../../tools/fetch'
import { AxiosResponse } from 'axios'
import Form, { IFormObject, IFormValue } from '../../../components/Form'
import { Link } from 'react-router-dom'
import i18n from '../../../tools/i18n'
import { Mail, UserCheck } from 'react-feather'
import { IAdminCohortObject } from '../../../store/admin/reducer'
import { ADMINCOHORTS_EDITSUCCESS, ADMINCOHORTS_FETCH, ADMIN_FETCHSUBJECTS } from '../../../store/admin/constants'
import { ISubjectObject } from '../../../store/cohorts/reducer'

const inputs: IFormObject[] = [
  {
    name: 'titre',
    title: '::Titre',
    type: 'text',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'description',
    title: '::Description',
    type: 'text',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'logo',
    title: '::Logo',
    type: 'image',
    half: false,
    validation: { presence: { allowEmpty: true, message: 'NONE' } },
  },
  {
    name: 'image',
    title: '::Image de fond',
    type: 'image',
    half: false,
    validation: { presence: { allowEmpty: true, message: 'NONE' } },
  },
]

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void
  subject: ISubjectObject
  closeForm: () => void
}

interface IState {
  requesting: boolean
  errored: boolean
  step: '1' | '2'
  success: boolean
  dv: IFormValue[]
}
class EditSubject extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    let dv: IFormValue[] = []
    Object.keys(this.props.subject).forEach((key) => {
      dv.push({
        name: key,
        // @ts-ignore
        value: this.props.subject[key],
      })
    })
    this.state = {
      requesting: false,
      errored: false,
      step: '1',
      success: false,
      dv: dv,
    }
  }

  public render(): JSX.Element | null {
    return (
      <div>
        <Form
          forms={inputs}
          defaultValues={this.state.dv}
          buttonText={'Enregistrer'}
          onValidSubmit={this.onRequest}
          loading={this.state.requesting}
          mainError={this.state.errored}
          placeholder={true}
          label={true}
          mainErrorText={'Impossible de se connecter au serveur.'}
          key={'form1editcohort'}
          //injectedErrors={this.state.injectedErrors}
        />
      </div>
    )
  }
  private onRequest = (values: { [key: string]: any }) => {
    this.setState({ requesting: true, errored: false }, async () => {
      try {
        const formData = new FormData()
        Object.keys(values).forEach((key) => {
          if (key === 'subject') {
            formData.append(key, values[key].id)
          } else formData.append(key, values[key])
        })

        const request: AxiosResponse = await API.post('/admin/subjects/edit/' + this.props.subject.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        this.props.dispatch({ type: ADMIN_FETCHSUBJECTS, payload: {} })

        this.setState({ requesting: false, errored: false }, () => setTimeout(() => this.props.closeForm(), 500))
      } catch (error) {
        this.setState({ requesting: false, errored: true })
      }
    })
  }

  onChangeInput = (name: string, value: string) => {
    //@ts-ignore
    this.setState({ [name]: value })
  }
}

export default connect((store: IStore) => ({}))(EditSubject)
