import React, { ChangeEvent, CSSProperties } from 'react'
import { IStore } from '../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR } from '../../store/user/constants'
import { IAdminCohortObject, ISubjectAdminObject } from '../../store/admin/reducer'
import { ADMINCOHORTS_EDITSUCCESS, ADMINCOHORTS_FETCH, ADMIN_FETCHSUBJECTS } from '../../store/admin/constants'

import Select from 'react-select'

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void

  subjects: ISubjectAdminObject[]

  value: any
  onChange: (name: string, value: string) => void
  name: string
  style?: CSSProperties
  containerStyle?: CSSProperties
  placeholder?: string
  locked?: boolean
}

interface IState {}
class EditCohort extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
    this.props.dispatch({ type: LOGIN_ERROR, payload: '' })
  }
  async componentDidMount() {
    this.props.dispatch({ type: ADMIN_FETCHSUBJECTS, payload: {} })
  }

  public render(): JSX.Element | null {
    return (
      <div style={{ ...this.props.containerStyle }}>
        <Select
          value={this.props.value}
          name="subjects-select"
          className="react-select-container"
          classNamePrefix="react-select"
          options={this.props.subjects}
          getOptionLabel={(option: ISubjectAdminObject) => `${option.titre}`}
          onChange={this.onChange}
          isOptionSelected={(option: ISubjectAdminObject, selected: any) => option.id === selected.id}
        />
      </div>
    )
  }

  onChange = (newValue: any) => {
    this.props.onChange(this.props.name, newValue)
  }
}

export default connect((store: IStore) => ({
  loading: store.admin.subjects.requesting,
  error: store.admin.subjects.error,
  subjects: store.admin.subjects.datas,
}))(EditCohort)
