import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  APP_OPENED,
  APP_RUNNING,
  APP_RUNNING_TRACK_FAIL,
  APP_RUNNING_TRACK_SUCCESS,
  TRACKER_SET_PROGRAM,
  USER_JUST_REGISTERED,
  OPENNING_RESSOURCE,
} from './constants'
import { IStore } from '..'
import { IUserObject } from '../user/reducer'
import { trackingPush } from './actions'
import { ITrackerState } from './reducer'
import Intercom from '../../tools/intercom'
import { ACTION_SET } from '../actions/constants'
import { getLang } from '../../tools/i18n'

export default function* trackerWatcher(): IterableIterator<any> {
  yield takeLatest([APP_OPENED], openingApp)
  yield takeLatest([APP_RUNNING], runningApp)
  yield takeLatest([USER_JUST_REGISTERED], userRegistrationDelay)
  // @ts-ignore
  yield takeLatest([OPENNING_RESSOURCE], openningRessource)
}

function* openingApp(): any {
  const user: IUserObject = yield select((state: IStore) => state.user.data)
  yield put({
    type: TRACKER_SET_PROGRAM,
    payload: { programId: undefined },
  })
  yield put({
    type: APP_RUNNING,
    payload: {},
  })

  let userDatas: any = undefined
  if (user && user.id !== 0) {
    userDatas = {
      email: user.email,
      name: `${user.firstname}`,
      user_id: `fasterclass-user-${user.id}`,
      user_hash: user.userHash,
      language_override: getLang(),
      horizontal_padding: 20,
      vertical_padding: 20,
      companies: [
        {
          name: user.company?.name || '',
          company_id: `company-${user.company?.id}` || '',
        },
      ],
    }
    Intercom.init(userDatas)
  }
}

function* runningApp(): any {
  const tracking: ITrackerState = yield select((state: IStore) => state.tracker)
  const vv: boolean = yield call(trackingPush, { programId: tracking.currentProgramId, count: tracking.count })
  if (!vv) {
    return yield put({
      type: APP_RUNNING_TRACK_FAIL,
      payload: {},
    })
  }
  return yield put({
    type: APP_RUNNING_TRACK_SUCCESS,
    payload: {},
  })
}

function* userRegistrationDelay({ payload }: any): any {
  setTimeout(() => Intercom.logEvent('user-inscription', {}), 5000)
}
function* openningRessource({ payload }: { payload: { id: number; title: string } }): any {
  Intercom.logEvent(`ressource-ouverte`, { titre: payload.title, id: payload.id })
}
