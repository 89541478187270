import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from './login/index'
import MagicLink from './Magic/index'
import './OfflineRouter.scss'
import Logo from '../../assets/images/logo_fasterclass.png'
import LangPicker from '../../components/LangPicker'
import { connect } from 'react-redux'
import { IStore } from '../../store'
import { USER_LANG_CHANGE } from '../../store/user/constants'
interface IProps {
  dispatch: any
  lang: 'fr' | 'en'
}
class OfflineLayout extends React.Component<IProps> {
  render() {
    return (
      <div className="offlineLayout">
        <div className="offlineLayout__left">
          <img className="offlineLayout__logo" src={Logo} />
          <div className="offlineLayout__container">
            <Switch>
              <Route exact path="/offline/" component={Login} />
              <Route exact path="/offline/magic/:magiclink" component={MagicLink} />
              <Redirect to="/offline/" />
            </Switch>
          </div>
          <div className="offlineLayout__containerLang">
            <LangPicker lang={this.props.lang} toggleLang={this.toggleLang} />
          </div>
        </div>
        <div className="offlineLayout__right"></div>
      </div>
    )
  }

  private toggleLang = (lang: 'en' | 'fr') => {
    this.props.dispatch({ type: USER_LANG_CHANGE, payload: { lang } })
  }
}

export default connect((store: IStore) => ({
  lang: store.user.lang,
}))(OfflineLayout)
