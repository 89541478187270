export const LOGIN: string = 'LOGIN'
export const LOGIN_REQUEST: string = 'LOGIN_REQUEST'
export const LOGIN_ERROR: string = 'LOGIN_ERROR'
export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS'
export const USER_SET_RATED_TRUE: string = 'USER_SET_RATED_TRUE'
export const USER_LOGOUT: string = 'USER_LOGOUT'
export const USER_LOGOUT_PROCESS: string = 'USER_LOGOUT_PROCESS'

export const USER_RELOAD_DATA: string = 'USER_RELOAD_DATA'

export const USER_UPDATE: string = 'USER_UPDATE'
export const USER_UPDATE_SUCCESS: string = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_REQUESTING: string = 'USER_UPDATE_REQUESTING'
export const USER_UPDATE_ERROR: string = 'USER_UPDATE_ERROR'

export const USER_UPDATE_PWD: string = 'USER_UPDATE_PWD'
export const USER_UPDATE_PWD_SUCCESS: string = 'USER_UPDATE_PWD_SUCCESS'
export const USER_UPDATE_PWD_REQUESTING: string = 'USER_UPDATE_PWD_REQUESTING'
export const USER_UPDATE_PWD_KNOWN_ERROR: string = 'USER_UPDATE_PWD_KNOWN_ERROR'
export const USER_UPDATE_PWD_ERROR: string = 'USER_UPDATE_PWD_ERROR'

export const USER_RESET_ERROR: string = 'USER_RESET_ERROR'

export const USER_LANG_CHANGE: string = 'USER_LANG_CHANGE'
export const USER_MAGIC: string = 'USER_MAGIC'
