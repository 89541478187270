import { USER_LOGOUT } from '../user/constants'
import {
  COHORTS_FETCH,
  COHORTS_FETCH_ERROR,
  COHORTS_FETCH_REQUESTING,
  COHORTS_FETCH_SUCCESS,
  COHORT_PROGRESS,
} from './constants'

export interface ICohortObject {
  id: number
  label: string
  labelEn: string

  companyType: string // inter - intra

  barCode: string
  visioLink: string
  //welcomeMailDate: this.label,
  //endMailDate: this.label,
  startDate: string
  endAccesDate: string
  scorm: string
  scormEn: string
  ressources: IRessourceObject[]
  participate: IParticipateObject
  subject: ISubjectObject
  //externTracker: this.label,
}
export interface IParticipateObject {
  id: number
  startDate: string
  endDate: string
  completion: number
}
export interface ISubjectObject {
  id: number
  titre: string
  description: string
  logo: string

  titreEn: string
  descriptionEn: string
  logoEn: string

  image: string
}
export interface IRessourceObject {
  id: number
  title: string
  file: string
  titleEn: string
  fileEn: string

  image: string
  type: string
}

export interface ICohortObject {
  id: number
  label: string
  labelEn: string

  companyType: string // inter - intra

  barCode: string
  visioLink: string
  //welcomeMailDate: this.label,
  //endMailDate: this.label,
  startDate: string
  endAccesDate: string
  scorm: string
  scormEn: string
  //externTracker: this.label,
}

export interface ICohortsState {
  isRequesting: boolean
  cohorts: ICohortObject[]
  error: boolean
}

const initialState: ICohortsState = {
  isRequesting: true,
  cohorts: [],
  error: false,
}

export default (
  state: ICohortsState = initialState,
  { type, payload }: { type: string; payload: any },
): ICohortsState => {
  switch (type) {
    case COHORT_PROGRESS:
      return {
        ...state,
        cohorts: state.cohorts.map((cohort) => {
          if (cohort.id === payload.cohortId) {
            let cohortNew = {
              ...cohort,
              participate: {
                ...cohort.participate,
                completion: payload.percent,
              },
            }
            return cohortNew
          }
          return cohort
        }),
      }
    case COHORTS_FETCH_REQUESTING:
      return {
        ...state,
        isRequesting: true,
        error: false,
      }
    case COHORTS_FETCH_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: true,
      }
    case COHORTS_FETCH_SUCCESS:
      return {
        ...state,
        cohorts: payload,
        isRequesting: false,
        error: false,
      }
    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
