import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import GreenLight from '../../../components/Texts/GreenLight'
import Button from '../../../components/Button'
import {
  Activity,
  Archive,
  ArrowLeft,
  ChevronLeft,
  Crosshair,
  Download,
  Edit,
  Mail,
  Plus,
  Trash2,
  User,
  UserPlus,
  X,
} from 'react-feather'
import theme from '../../../theme'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import ActionCard from '../../../components/ActionCard'
import i18n from '../../../tools/i18n'
import API from '../../../tools/fetch'
import Loader from '../../../components/Loader'
import { ICohortObject } from '../../../store/cohorts/reducer'
import Header from './Header/Header'
import config from '../../../config'
import { cohortsFetchAction } from '../../../store/cohorts/actions'
import { IUserObject } from '../../../store/types'
import 'scorm-again'
import EditScorm from './edit'
import AddRessource from './addRessource'
import { COHORT_PROGRESS } from '../../../store/cohorts/constants'
import { IAdminCohortObject, IScormDetailsAdminObject } from '../../../store/admin/reducer'
import {
  ADMINCOHORT_FETCH,
  ADMIN_DELETE_PARTICIPATE,
  ADMIN_DELETE_RESSOURCE,
  ADMIN_FETCHSCORM,
} from '../../../store/admin/constants'
import moment from 'moment'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import AddApprenant from './addApprenant'

interface IProps {
  match: any
  scorm?: IScormDetailsAdminObject
  requesting: boolean
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  history: any
  lang: 'fr' | 'en'
  scormId: number
  user: IUserObject
}
interface IState {}
class ScormAdmin extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {
    this.props.dispatch({ type: ADMIN_FETCHSCORM, payload: { scormId: this.props.scormId } })
  }
  public render(): JSX.Element | null {
    const { lang, scorm, history, requesting } = this.props
    const {} = this.state
    return (
      <div style={{ position: 'relative' }}>
        <Loader loading={this.props.requesting} insert={true} />
        {!requesting && scorm !== undefined ? (
          <div className="adminList__dataContainer">
            <div className="adminList__buttonContainer">
              <Button onClick={this.returnList} theme="greyLight" style={{ marginRight: 20 }}>
                <ChevronLeft size={24} />
                Retour
              </Button>
              <Popup
                trigger={
                  <Button theme="primary" style={{ marginRight: 20 }}>
                    <Edit size={24} />
                    Modifier
                  </Button>
                }
                modal={true}
                closeOnDocumentClick={false}
                position="top left"
              >
                {(close: () => void) => (
                  <div className="adminList__popupContainer">
                    <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                      <X size={24} />
                      Fermer
                    </Button>
                    {
                      // @ts-ignore
                      <EditScorm closeForm={close} scorm={this.props.scorm} />
                    }
                  </div>
                )}
              </Popup>
              <Button onClick={this.openScormClient} theme="primary" style={{ marginRight: 20 }}>
                <Download size={24} />
                Télécharger scorm pour client
              </Button>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Label</div>
              <div className="adminList__dataValue">{scorm.label}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Entreprise</div>
              <div className="adminList__dataValue">{scorm.company}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Date de fin d'accès</div>
              <div className="adminList__dataValue">{moment(scorm.endAccesDate).format('DD/MM/YYYY')}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Scorm</div>
              <div className="adminList__dataValue">{scorm.scorm}</div>
            </div>
            <div className="adminList__dataDivider" />
            <div className="adminList__data">
              <div className="adminList__buttonContainer">
                <div className="adminList__dataLabel">Apprenants</div>
              </div>
              <div className="adminList__buttonContainer">
                <Button onClick={this.openCSVDetails} theme="primary" style={{ marginRight: 20 }}>
                  <Download size={24} />
                  Télécharger données avancées
                </Button>
              </div>
              {scorm.participates.length > 0 && (
                <table className="adminList__table">
                  <tr>
                    <th></th>
                    <th>ID Lms</th>
                    <th>Nom</th>
                    <th>Complétion</th>
                    <th>Temps passé</th>
                    <th>Date début</th>
                    <th>Date dernière co</th>
                    <th></th>
                  </tr>
                  {scorm.participates.map((apprenant) => (
                    <tr>
                      <td>
                        <User size={24} color="#fff" style={{ marginTop: 3 }} />
                      </td>
                      <td>{apprenant.idLMS}</td>
                      <td>{apprenant.lastname}</td>
                      <td>{apprenant.completion} %</td>
                      <td>{apprenant.timer} min</td>
                      <td>{moment(apprenant.startDate).format('DD/MM/YYYY')}</td>
                      <td>{moment(apprenant.lastDate).format('DD/MM/YYYY')}</td>
                      <td></td>
                    </tr>
                  ))}
                </table>
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
  private returnList = () => {
    this.props.history.push('/online/admin/scorms/list')
  }
  private openScormClient = () => {
    window.open(config.scormApiUrl + '/admin/scorms/download/' + (this.props.scorm?.id || ''), '_blank', 'noreferrer')
  }
  private openCSVDetails = () => {
    window.open(config.scormApiUrl + '/admin/scorms/csv/' + (this.props.scorm?.id || ''), '_blank', 'noreferrer')
  }
  private deleteParticipate = (participateId: number, close: () => void) => {
    this.props.dispatch({ type: ADMIN_DELETE_PARTICIPATE, payload: { participateId, cohortId: this.props.scormId } })
    close()
  }
}

export default connect((store: IStore, props: IProps) => {
  return {
    scormId: props.match.params.id,
    scorm: store.admin.scorm.datas,
    error: store.admin.scorm.error,
    requesting: store.admin.scorm.requesting,
    lang: store.user.lang,
    user: store.user.data,
  }
})(ScormAdmin)
