import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import GreenLight from '../../../components/Texts/GreenLight'
import Button from '../../../components/Button'
import { ArrowLeft } from 'react-feather'
import theme from '../../../theme'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import ActionCard from '../../../components/ActionCard'
import i18n from '../../../tools/i18n'
import API from '../../../tools/fetch'
import Loader from '../../../components/Loader'
import { ICohortObject } from '../../../store/cohorts/reducer'
import Header from './Header/Header'
import config from '../../../config'
import { cohortsFetchAction } from '../../../store/cohorts/actions'
import { IUserObject } from '../../../store/types'
import 'scorm-again'
import Intercom from '../../../tools/intercom'
import { COHORT_PROGRESS } from '../../../store/cohorts/constants'

interface IProps {
  match: any
  cohort: ICohortObject
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  history: any
  lang: 'fr' | 'en'
  user: IUserObject
}
interface IState {
  loading: boolean
}
class ScormReader extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = { loading: true }
    var settings = {}
    var learner = {
      learner_id: '' + props.user.id,
      learner_name: props.user.lastname + ' ' + props.user.firstname,
      suspend_data: 'viewed=1|lastviewedslide=1',
    }
    // @ts-ignore
    window.API_1484_11 = new window.Scorm2004API(settings)
    // @ts-ignore
    window.API_1484_11.loadFromJSON(learner)
  }
  async componentDidMount() {
    this.setState({ loading: false })
    // @ts-ignore
    window.API_1484_11.on('SetValue.cmi.*', (CMIElement, value) => {
      if (CMIElement === 'cmi.progress_measure') {
        let percent = Math.round(value * 100)
        this.progress(percent)
      }
    })
  }
  public render(): JSX.Element | null {
    const { lang, cohort, history } = this.props
    const { loading } = this.state
    console.log(config)
    if (!cohort) return null
    return (
      <div>
        <Header lang={lang} cohort={cohort} history={history}>
          <iframe
            style={{ flex: 1, border: 0 }}
            src={config.apiUrl + '/cohorts/' + (lang === 'fr' ? cohort.scorm : cohort.scormEn) + '/index.html'}
          ></iframe>
          {/*<iframe style={{ flex: 1, border: 0 }} src={'/scorm/index.html'}></iframe>*/}
        </Header>
      </div>
    )
  }
  private progress = (percent: number) => {
    if (this.props.cohort.participate.id > 0) {
      this.props.dispatch({
        type: COHORT_PROGRESS,
        payload: {
          percent: percent,
          cohortId: this.props.cohort.id,
        },
      })
    }
  }
}

export default connect((store: IStore, props: IProps) => {
  let cohort: ICohortObject | undefined = store.cohorts.cohorts.find((c) => c.id.toString() === props.match.params.id)
  let cohortV: ICohortObject
  if (cohort === undefined) {
    props.history.replace('/online/')
    // @ts-ignore
    cohortV = {}
  } else {
    cohortV = cohort
  }
  return {
    cohort: cohortV,
    lang: store.user.lang,
    user: store.user.data,
  }
})(ScormReader)
