import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo_fasterclass.png'
import './Header.scss'
import { HelpCircle, Menu, MessageCircle, User, X, Settings } from 'react-feather'
import NavMenu from './NavMenu'
import Popup from 'reactjs-popup'
import { IPremiumCompanyObject, IUserObject } from '../../store/user/reducer'
import imageResizer from '../../tools/imageResizer'
import i18n from '../../tools/i18n'
import LangPicker from '../LangPicker'
import Button from '../Button'
import Intercom from '../../tools/intercom'

interface IProps {
  premiumCompany: IPremiumCompanyObject | undefined
  processLogout: () => void
  lang: 'en' | 'fr'
  toggleLang: (lang: 'en' | 'fr') => void
  user: IUserObject
  history: any
}
interface IState {
  menuOpened: boolean
  sponsorOpened: boolean
}

class Header extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = { menuOpened: false, sponsorOpened: false }
  }
  componentDidMount() {
    //document.addEventListener('mousedown', this.closeMenu, true)
  }
  componentWillUnmount() {
    //document.removeEventListener('mousedown', this.closeMenu, true)
  }
  private closeMenu = () => {
    if (this.state.menuOpened || this.state.sponsorOpened) {
      setTimeout(() => this.setState({ menuOpened: false, sponsorOpened: false }), 200)
    }
  }
  public render(): JSX.Element {
    const { premiumCompany } = this.props
    return (
      <div>
        <div className="header">
          <div className="header__container">
            <Link to="/online/" className="header__logo-box">
              <img src={Logo} alt="logo" className="header__logo" />
            </Link>

            <div className="header__options">
              <Button onClick={this.toggleMenu}>
                <Menu size={24} />
                {i18n.t('pages.online.home.menu.btn')}
              </Button>
            </div>
          </div>
        </div>
        {this.props.children}

        <div
          className="header__sideBarContainer"
          style={this.state.menuOpened ? { display: 'flex' } : { display: 'none' }}
        >
          <div className="header__sideBarGrey" onClick={this.toggleMenu}></div>
          <div className="header__sideBar">
            <div className="header__sideBarMenu">
              <div className="header__sideBarMenuButton">
                <Button onClick={this.toggleMenu} theme="greyLight">
                  <X size={24} />
                  {i18n.t('pages.online.home.menu.close')}
                </Button>
              </div>
              <div className="header__sideBarMenuList">
                <Button
                  onClick={() => {
                    window.open(i18n.t('pages.online.home.menu.faqlink'), '_blank', 'noreferrer')
                  }}
                  theme="greyLight"
                  style={{ width: 'auto', justifyContent: 'flex-start', marginBottom: 12 }}
                >
                  <HelpCircle size={24} />
                  {i18n.t('pages.online.home.menu.faq')}
                </Button>
                <Button
                  onClick={() => {
                    Intercom.action('showMessages', {})
                  }}
                  theme="greyLight"
                  style={{ width: 'auto', justifyContent: 'flex-start', marginBottom: 12 }}
                >
                  <MessageCircle size={24} />
                  {i18n.t('pages.online.home.menu.contact')}
                </Button>
                <Button
                  onClick={this.props.processLogout}
                  theme="greyLight"
                  style={{ width: 'auto', justifyContent: 'flex-start', marginBottom: 12 }}
                >
                  <X size={24} />
                  {i18n.t('pages.online.home.menu.logout')}
                </Button>

                {this.props.user.admin && (
                  <Button
                    onClick={this.goToAdmin}
                    theme="greyLight"
                    style={{ width: 'auto', justifyContent: 'flex-start', marginBottom: 12 }}
                  >
                    <Settings size={24} />
                    Administration
                  </Button>
                )}
              </div>
            </div>
            <LangPicker lang={this.props.lang} toggleLang={this.props.toggleLang} />
          </div>
        </div>
      </div>
    )
  }
  private toggleMenu = () => {
    this.setState({ menuOpened: !this.state.menuOpened })
  }

  private goToAdmin = () => {
    this.props.history.push('/online/admin/')
  }
}

export default Header
