import React from 'react'
import { connect } from 'react-redux'
import { IStore, IUserObject } from '../../../store/types'
import Loader from '../../../components/Loader'
import './Home.scss'
import i18n from '../../../tools/i18n'
import 'react-multi-carousel/lib/styles.css'
import { ICohortObject } from '../../../store/cohorts/reducer'
import moment from 'moment'
import Button from '../../../components/Button'
import { Camera, Link, Video } from 'react-feather'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1140 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1140, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
interface IProps {
  navigation: any
  loading: boolean
  error: boolean
  cohorts: ICohortObject[]
  history: any
  user: IUserObject
  lang: 'en' | 'fr'
  dispatch: (object: { type: string; payload?: any }) => void
}

class Home extends React.Component<IProps, any> {
  render() {
    const { loading, cohorts, lang, user } = this.props
    if (loading) {
      return <Loader loading={this.props.loading} />
    }
    return (
      <div className="home">
        <div className="home__title">Hey {user.firstname},</div>
        <div className="home__subtitle">{i18n.t('pages.online.home.nbcohorts', { count: cohorts.length })}</div>
        {cohorts.map((cohort) => {
          let endDate = cohort.participate.endDate || cohort.endAccesDate
          return (
            <div className="home__cohort" key={`home-cohorts-${cohort.id}`}>
              <div
                className="home__cohortSubContainer"
                style={{ backgroundImage: 'url(' + cohort.subject.image + ')' }}
              >
                <div className="home__cohortContainer">
                  <div
                    className="home__cohortImgContainer"
                    style={{ backgroundImage: 'url(' + cohort.subject.image + ')' }}
                  >
                    <img src={lang === 'fr' ? cohort.subject.logo : cohort.subject.logoEn} />
                  </div>
                  <div className="home__cohortOtherContainer">
                    <div className="home__cohortOtherTop">
                      <div className="home__cohortOtherTopLeft">
                        <div className="home__cohortOtherTopLeftT1">
                          {lang === 'fr' ? cohort.subject.titre : cohort.subject.titreEn}
                        </div>
                        <div className="home__cohortOtherTopLeftT2">
                          {lang === 'fr' ? cohort.subject.description : cohort.subject.descriptionEn}
                        </div>
                        <div className="home__cohortOtherTopLeftT3">
                          {moment() > moment(endDate) ? (
                            <div className="home__cohortOtherTopLeftT3G">{i18n.t('pages.online.home.ended')}</div>
                          ) : moment() > moment(cohort.startDate) ? (
                            <div className="home__cohortOtherTopLeftT3P">{i18n.t('pages.online.home.now')}</div>
                          ) : (
                            <div className="home__cohortOtherTopLeftT3P">{i18n.t('pages.online.home.startsoon')}</div>
                          )}
                        </div>
                      </div>
                      {moment() >= moment(cohort.startDate) && cohort.participate.id > 0 ? (
                        <div className="home__cohortOtherTopRight">
                          <div className="home__cohortOtherTopRightText">{cohort.participate.completion} %</div>
                          <div className="home__cohortOtherTopRightBar">
                            <div
                              className="home__cohortOtherTopRightBarBar"
                              style={{ width: cohort.participate.completion }}
                            ></div>
                          </div>
                        </div>
                      ) : (
                        <div className="home__cohortOtherTopRight" />
                      )}
                    </div>
                    <div className="home__cohortOtherBottom">
                      <div className="home__cohortOtherBottomLeft">
                        <div className="home__cohortOtherBottomLeftT1">{i18n.t('pages.online.home.accessto')}</div>
                        <div className="home__cohortOtherBottomLeftT2">{moment(endDate).format('D MMMM YYYY')}</div>
                      </div>
                      <div className="home__cohortOtherBottomRight">
                        {moment() >= moment(cohort.startDate) && moment() <= moment(endDate) ? (
                          <Button onClick={() => this.goToCohort(cohort.id)}>
                            {cohort.participate.completion === 0
                              ? i18n.t('pages.online.home.start')
                              : cohort.participate.completion === 100
                              ? i18n.t('pages.online.home.restart')
                              : i18n.t('pages.online.home.continue')}
                          </Button>
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cohort.visioLink && cohort.visioLink !== 'NONE' && (
                <div className="home__cohortVisioLink">
                  <div className="home__cohortVisioLinkTitle">
                    <Video size={24} color={'#26202D'} /> {i18n.t('pages.online.home.visiolink')}
                  </div>

                  <div className="home__cohortVisioLinkContainer">
                    <div className="home__cohortVisioLinkLink">{cohort.visioLink}</div>
                    <div className="home__cohortVisioLinkButton">
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(cohort.visioLink)
                        }}
                        theme={'dark'}
                        style={{
                          fontSize: 10,
                          height: 26,
                          padding: '0px 20px',
                        }}
                      >
                        <Link size={12} />
                        {i18n.t('pages.online.home.copylink')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }
  private goToCohort = (cohortId: number) => {
    this.props.history.push('/online/cohort/' + cohortId + '/')
  }
}
export default connect((store: IStore) => ({
  loading: store.cohorts.isRequesting,
  error: store.cohorts.error,
  cohorts: store.cohorts.cohorts,
  lang: store.user.lang,
  user: store.user.data,
}))(Home)
