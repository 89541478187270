import { USER_LOGOUT } from '../user/constants'
import { IUserState } from '../user/reducer'
import {
  ADMINCOHORTS_FETCH_ERROR,
  ADMINCOHORTS_FETCH_REQUESTING,
  ADMINCOHORTS_FETCH_SUCCESS,
  ADMINCOHORT_FETCH,
  ADMINCOHORT_FETCH_ERROR,
  ADMINCOHORT_FETCH_REQUESTING,
  ADMINCOHORT_FETCH_SUCCESS,
  ADMINCOHORTS_EDITSUCCESS,
  ADMIN_FETCHSUBJECTS_REQUESTING,
  ADMIN_FETCHSUBJECTS_SUCCESS,
  ADMIN_FETCHSUBJECTS_ERROR,
  ADMIN_DELETE_RESSOURCE_SUCCESS,
  ADMIN_FETCHAPPRENANTS_REQUESTING,
  ADMIN_FETCHAPPRENANTS_SUCCESS,
  ADMIN_FETCHAPPRENANTS_ERROR,
  ADMIN_DELETE_PARTICIPATE_SUCCESS,
  ADMIN_FETCH_APPRENANT_REQUESTING,
  ADMIN_FETCH_APPRENANT_ERROR,
  ADMIN_FETCH_APPRENANT_SUCCESS,
  ADMIN_DELETE_PARTICIPATE_USER_SUCCESS,
  ADMIN_FETCHSCORMS_SUCCESS,
  ADMIN_FETCHSCORMS_REQUESTING,
  ADMIN_FETCHSCORMS_ERROR,
  ADMIN_FETCHSCORM_ERROR,
  ADMIN_FETCHSCORM_REQUESTING,
  ADMIN_FETCHSCORM_SUCCESS,
  ADMIN_SCORM_EDITSUCCESS,
} from './constants'

export interface IAdminCohortObject {
  id: number
  label: String
  companyType: string // inter - intra
  barCode: string
  visioLink: string
  startDate: string
  endAccesDate: string
  scorm: string
  externTracker: string
  forPartenaire: boolean
  ressources: IRessourceObject[]
  participates: IParticipateAdminObject[]
  subject: ISubjectAdminObject
}
export interface IAdminCohortLightObject {
  id: number
  label: string
  labelEn: string
  companyType: string // inter - intra
  barCode: string
  visioLink: string
  startDate: string
  endAccesDate: string
  scorm: string
  scormEn: string
  ressources: number
  participates: number
  subject: ISubjectAdminObject
}
export interface IParticipateAdminObject {
  id: number
  startDate: string
  endDate: string
  completion: number
  user: IUserAdminObject
}
export interface IUserAdminObject {
  id: number
  firstname: string
  lastname: string
  email: string
  isPartenaire: boolean
  company: {
    name: string
  }
}
export interface IApprenantAdminObject {
  id: number
  firstname: string
  lastname: string
  email: string
  isPartenaire: boolean
  lastMessage?: string
  company: {
    name: string
  }
  participates: {
    id: number
    startDate: string
    endDate: string
    completion: number
    cohort: {
      id: number
      label: string
      subject: ISubjectAdminObject
    }
  }[]
}
export interface ISubjectAdminObject {
  id: number
  titre: string
  description: string
  logo: string
  titreEn: string
  descriptionEn: string
  logoEn: string
  image: string
}
export interface IRessourceObject {
  id: number
  title: string
  file: string
  titleEn: string
  fileEn: string
  image: string
  type: string
}

export interface IScormAdminObject {
  id: number
  label: string
  company: string
  endAccesDate: string
  scorm: string
  securityKey: string
  nbParticipate: number
}

export interface IScormDetailsAdminObject {
  id: number
  label: string
  company: string
  endAccesDate: string
  scorm: string
  securityKey: string
  participates: {
    id: number
    startDate: string
    lastDate: string
    completion: number
    lmsLastTrack: string
    timer: number
    firstname: string
    lastname: string
    idLMS: string
  }[]
}

export interface IAdminState {
  cohorts: {
    datas: IAdminCohortLightObject[]
    requesting: boolean
    error: boolean
  }
  cohort: {
    datas?: IAdminCohortObject
    requesting: boolean
    error: boolean
  }
  subjects: {
    datas: ISubjectAdminObject[]
    requesting: boolean
    error: boolean
  }

  apprenants: {
    datas: IUserAdminObject[]
    requesting: boolean
    error: boolean
  }
  scorms: {
    datas: IScormAdminObject[]
    requesting: boolean
    error: boolean
  }
  scorm: {
    datas?: IScormDetailsAdminObject
    requesting: boolean
    error: boolean
  }
  apprenant: {
    datas?: IApprenantAdminObject
    requesting: boolean
    error: boolean
  }
}

const initialState: IAdminState = {
  cohorts: {
    datas: [],
    requesting: false,
    error: false,
  },
  cohort: {
    datas: undefined,
    requesting: false,
    error: false,
  },
  subjects: {
    datas: [],
    requesting: false,
    error: false,
  },
  apprenants: {
    datas: [],
    requesting: false,
    error: false,
  },
  apprenant: {
    datas: undefined,
    requesting: false,
    error: false,
  },
  scorms: {
    datas: [],
    requesting: false,
    error: false,
  },
  scorm: {
    datas: undefined,
    requesting: false,
    error: false,
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: IAdminState = initialState, { type, payload }: { type: string; payload: any }): IAdminState => {
  switch (type) {
    case ADMIN_DELETE_RESSOURCE_SUCCESS:
      return {
        ...state,
        cohort: {
          ...state.cohort,
          // @ts-ignore
          datas: {
            ...state.cohort.datas,
            ressources:
              state.cohort.datas?.ressources.filter((r) => {
                if (r.id === payload) return false
                return true
              }) || [],
          },
        },
      }
    case ADMIN_DELETE_PARTICIPATE_SUCCESS:
      return {
        ...state,
        cohort: {
          ...state.cohort,
          // @ts-ignore
          datas: {
            ...state.cohort.datas,
            participates:
              state.cohort.datas?.participates.filter((p) => {
                if (p.id === payload) return false
                return true
              }) || [],
          },
        },
      }
    case ADMIN_DELETE_PARTICIPATE_USER_SUCCESS:
      return {
        ...state,
        apprenant: {
          ...state.apprenant,
          // @ts-ignore
          datas: {
            ...state.apprenant.datas,
            participates:
              state.apprenant.datas?.participates.filter((p) => {
                if (p.id === payload) return false
                return true
              }) || [],
          },
        },
      }
    case ADMIN_FETCHSCORM_SUCCESS:
      return {
        ...state,
        scorm: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }
    case ADMIN_FETCHSCORM_REQUESTING:
      return {
        ...state,
        scorm: {
          ...state.scorm,
          requesting: true,
          error: false,
        },
      }
    case ADMIN_FETCHSCORM_ERROR:
      return {
        ...state,
        scorm: {
          ...state.scorm,
          requesting: false,
          error: true,
        },
      }
    case ADMIN_FETCHSCORMS_SUCCESS:
      return {
        ...state,
        scorms: {
          datas: payload,
          requesting: true,
          error: false,
        },
      }
    case ADMIN_FETCHSCORMS_REQUESTING:
      return {
        ...state,
        scorms: {
          ...state.scorms,
          requesting: true,
          error: false,
        },
      }
    case ADMIN_FETCHSCORMS_ERROR:
      return {
        ...state,
        scorms: {
          ...state.scorms,
          requesting: false,
          error: true,
        },
      }
    case ADMIN_FETCH_APPRENANT_REQUESTING:
      return {
        ...state,
        apprenant: {
          ...state.apprenant,
          requesting: true,
          error: false,
        },
      }
    case ADMIN_FETCH_APPRENANT_SUCCESS:
      return {
        ...state,
        apprenant: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }
    case ADMIN_FETCH_APPRENANT_ERROR:
      return {
        ...state,
        apprenant: {
          ...state.apprenant,
          requesting: false,
          error: true,
        },
      }
    case ADMIN_FETCHAPPRENANTS_REQUESTING:
      return {
        ...state,
        apprenants: {
          ...state.apprenants,
          requesting: true,
          error: false,
        },
      }
    case ADMIN_FETCHAPPRENANTS_SUCCESS:
      return {
        ...state,
        apprenants: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }
    case ADMIN_FETCHAPPRENANTS_ERROR:
      return {
        ...state,
        apprenants: {
          ...state.apprenants,
          requesting: false,
          error: true,
        },
      }
    case ADMIN_FETCHSUBJECTS_REQUESTING:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          requesting: true,
          error: false,
        },
      }
    case ADMIN_FETCHSUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }
    case ADMIN_FETCHSUBJECTS_ERROR:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          requesting: false,
          error: true,
        },
      }
    case ADMINCOHORTS_FETCH_REQUESTING:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          requesting: true,
          error: false,
        },
      }
    case ADMINCOHORTS_FETCH_ERROR:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          requesting: false,
          error: true,
        },
      }
    case ADMINCOHORTS_FETCH_SUCCESS:
      return {
        ...state,
        cohorts: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }

    case ADMIN_SCORM_EDITSUCCESS:
      return {
        ...state,
        scorm: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }
    case ADMINCOHORTS_EDITSUCCESS:
      return {
        ...state,
        cohort: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }
    case ADMINCOHORT_FETCH:
      return {
        ...state,
        cohort: {
          ...initialState.cohort,
        },
      }
    case ADMINCOHORT_FETCH_REQUESTING:
      return {
        ...state,
        cohort: {
          ...state.cohort,
          requesting: true,
          error: false,
        },
      }
    case ADMINCOHORT_FETCH_ERROR:
      return {
        ...state,
        cohort: {
          ...state.cohort,
          requesting: false,
          error: true,
        },
      }
    case ADMINCOHORT_FETCH_SUCCESS:
      return {
        ...state,
        cohort: {
          datas: payload,
          requesting: false,
          error: false,
        },
      }
    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
