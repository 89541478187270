import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import GreenLight from '../../../components/Texts/GreenLight'
import Button from '../../../components/Button'
import {
  Activity,
  ArrowLeft,
  ChevronLeft,
  Crosshair,
  Download,
  Edit,
  Eye,
  Mail,
  Plus,
  Sidebar,
  Trash2,
  User,
  UserPlus,
  X,
} from 'react-feather'
import theme from '../../../theme'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import ActionCard from '../../../components/ActionCard'
import i18n from '../../../tools/i18n'
import API from '../../../tools/fetch'
import Loader from '../../../components/Loader'
import { ICohortObject } from '../../../store/cohorts/reducer'
import config from '../../../config'
import { cohortsFetchAction } from '../../../store/cohorts/actions'
import { IUserObject } from '../../../store/types'
import 'scorm-again'
import EditCohort from './edit'
import { COHORT_PROGRESS } from '../../../store/cohorts/constants'
import { IAdminCohortObject, IApprenantAdminObject } from '../../../store/admin/reducer'
import {
  ADMINCOHORT_FETCH,
  ADMIN_DELETE_PARTICIPATE,
  ADMIN_DELETE_PARTICIPATE_USER,
  ADMIN_DELETE_RESSOURCE,
  ADMIN_FETCH_APPRENANT,
  ADMIN_FETCH_APPRENANT_SUCCESS,
} from '../../../store/admin/constants'
import moment from 'moment'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import EditApprenant from './edit'
import AddCohort from './addCohort'

interface IProps {
  match: any
  apprenant?: IApprenantAdminObject
  requesting: boolean
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  history: any
  apprenantId: number
}
interface IState {}
class CohortAdmin extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {
    this.props.dispatch({ type: ADMIN_FETCH_APPRENANT, payload: { apprenantId: this.props.apprenantId } })
  }
  public render(): JSX.Element | null {
    const { apprenant, history, requesting } = this.props
    const {} = this.state
    return (
      <div style={{ position: 'relative' }}>
        <Loader loading={this.props.requesting} insert={true} />
        {!requesting && apprenant !== undefined ? (
          <div className="adminList__dataContainer">
            <div className="adminList__buttonContainer">
              <Button onClick={this.returnList} theme="greyLight" style={{ marginRight: 20 }}>
                <ChevronLeft size={24} />
                Retour
              </Button>
              <Popup
                trigger={
                  <Button theme="primary" style={{ marginRight: 20 }}>
                    <Edit size={24} />
                    Modifier
                  </Button>
                }
                modal={true}
                closeOnDocumentClick={false}
                position="top left"
              >
                {(close: () => void) => (
                  <div className="adminList__popupContainer">
                    <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                      <X size={24} />
                      Fermer
                    </Button>
                    {
                      // @ts-ignore
                      <EditApprenant closeForm={close} apprenant={this.props.apprenant} />
                    }
                  </div>
                )}
              </Popup>

              <Popup
                trigger={
                  <Button theme="primary" style={{ marginRight: 20 }}>
                    <Mail size={24} />
                    Envoyer un lien d'invitation
                  </Button>
                }
                modal={true}
                closeOnDocumentClick={false}
                position="top left"
              >
                {(close: () => void) => (
                  <div className="adminList__popupContainer" style={{ display: 'flex', flexDirection: 'column' }}>
                    <h3>
                      Etes vous sur de vouloir envoyer un mail d'invitation à {apprenant.lastname} {apprenant.firstname}{' '}
                      ? Dernier email :{' '}
                      {apprenant.lastMessage ? moment(apprenant.lastMessage).format('DD/MM/YYYY') : 'Aucun'}
                    </h3>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        flex: 1,
                      }}
                    >
                      <Button
                        onClick={() => this.invitApprenant(apprenant.id, close)}
                        theme="primary"
                        style={{ marginBottom: 20 }}
                      >
                        <Mail size={24} />
                        Inviter
                      </Button>
                      <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                        <X size={24} />
                        Annuler
                      </Button>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Nom</div>
              <div className="adminList__dataValue">{apprenant.lastname}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Prénom</div>
              <div className="adminList__dataValue">{apprenant.firstname}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Email</div>
              <div className="adminList__dataValue">{apprenant.email}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Entreprise</div>
              <div className="adminList__dataValue">{apprenant.company.name}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Est un partenaire</div>
              <div className="adminList__dataValue">{apprenant.isPartenaire ? 'Oui' : 'Non'}</div>
            </div>
            <div className="adminList__dataDivider" />
            <div className="adminList__data">
              <div className="adminList__buttonContainer">
                <div className="adminList__dataLabel">Cohortes de l'apprenant</div>

                <Popup
                  trigger={
                    <Button
                      theme="primary"
                      style={{
                        justifyContent: 'center',
                        marginLeft: 15,
                        height: 40,
                        width: 40,
                        padding: 0,
                        borderRadius: '50%',
                        textAlign: 'center',
                        display: 'flex',
                      }}
                    >
                      <Plus size={24} style={{ marginRight: 0 }} />
                    </Button>
                  }
                  modal={true}
                  closeOnDocumentClick={false}
                  position="top left"
                >
                  {(close: () => void) => (
                    <div className="adminList__popupContainer">
                      {
                        // @ts-ignore
                        <AddCohort closeForm={close} apprenant={this.props.apprenant} />
                      }
                    </div>
                  )}
                </Popup>
              </div>
              {apprenant.participates.length > 0 && (
                <table className="adminList__table">
                  <tr>
                    <th></th>
                    <th>Label</th>
                    <th>Sujet</th>
                    <th>Avancement</th>
                    <th></th>
                  </tr>
                  {apprenant.participates.map((participate) => (
                    <tr>
                      <td>
                        <Sidebar size={24} color="#fff" style={{ marginTop: 3 }} />
                      </td>
                      <td>{participate.cohort.label}</td>
                      <td>{participate.cohort.subject.titre}</td>
                      <td>{participate.completion} %</td>
                      <td>
                        <div className="adminList__buttonList">
                          <Button
                            onClick={() => this.goToCohort(participate.cohort.id)}
                            theme="primary"
                            style={{
                              justifyContent: 'center',
                              marginRight: 15,
                              height: 30,
                              width: 30,
                              padding: 0,
                              borderRadius: '50%',
                              textAlign: 'center',
                              display: 'flex',
                            }}
                          >
                            <Eye size={20} style={{ marginRight: 0 }} />
                          </Button>
                          <Popup
                            trigger={
                              <Button
                                theme="error"
                                style={{
                                  justifyContent: 'center',
                                  marginRight: 85,
                                  height: 30,
                                  width: 30,
                                  padding: 0,
                                  borderRadius: '50%',
                                  textAlign: 'center',
                                  display: 'flex',
                                }}
                              >
                                <X size={20} style={{ marginRight: 0 }} />
                              </Button>
                            }
                            modal={true}
                            closeOnDocumentClick={false}
                            position="top left"
                          >
                            {(close: () => void) => (
                              <div
                                className="adminList__popupContainer"
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <h3>
                                  Etes vous sur de vouloir désinscrire {apprenant.lastname} {apprenant.firstname} de la
                                  cohorte {participate.cohort.label} ?
                                </h3>

                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                  }}
                                >
                                  <Button
                                    onClick={() => this.deleteParticipate(participate.id, participate.cohort.id, close)}
                                    theme="error"
                                    style={{ marginBottom: 20 }}
                                  >
                                    <Trash2 size={24} />
                                    Désinscrire
                                  </Button>
                                  <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                                    <X size={24} />
                                    Annuler
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Popup>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
  private returnList = () => {
    this.props.history.goBack()
  }
  private openRessource = (url: string) => {
    window.open(url, '_blank', 'noreferrer')
  }
  private deleteRessource = (ressourceId: number, close: () => void) => {
    this.props.dispatch({ type: ADMIN_DELETE_RESSOURCE, payload: ressourceId })
    close()
  }
  private deleteParticipate = (participateId: number, cohortId: number, close: () => void) => {
    this.props.dispatch({
      type: ADMIN_DELETE_PARTICIPATE_USER,
      payload: { participateId, cohortId },
    })
    close()
  }
  private goToCohort = (cohortId: number) => {
    this.props.history.push('/online/admin/cohort/' + cohortId + '/')
  }

  private invitApprenant = async (apprenantId: number, close: () => void) => {
    try {
      close()
      const request = await API.get('/admin/apprenants/invit/' + apprenantId)
      this.props.dispatch({ type: ADMIN_FETCH_APPRENANT_SUCCESS, payload: request.data })
    } catch (error) {}
  }
}

export default connect((store: IStore, props: IProps) => {
  return {
    apprenantId: props.match.params.id,
    apprenant: store.admin.apprenant.datas,
    error: store.admin.apprenant.error,
    requesting: store.admin.apprenant.requesting,
  }
})(CohortAdmin)
