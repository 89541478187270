import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import Header from '../../components/Header/Header'
import { IStore } from '../../store'
import { APP_RUNNING } from '../../store/trackEvent/constants'
import { IUserObject } from '../../store/types'
import { USER_LANG_CHANGE, USER_LOGOUT_PROCESS, USER_RELOAD_DATA } from '../../store/user/constants'

import Home from './Home'
import Settings from './Settings'
import './OnlineRouter.scss'
import ChangePassword from './ChangePassword'
import intercom from '../../tools/intercom'
import Loader from '../../components/Loader'
import ScormReader from './ScormReader'

interface IProps {
  user: IUserObject
  dispatch: (props: { type: string; payload?: any }) => void
  loadingCohorts: boolean
  loadingUser: boolean
  lang: 'en' | 'fr'
  history: any
}

class OnlineRouter extends React.Component<IProps> {
  private interval: any = undefined

  private refreshReaderData = () => {
    this.props.dispatch({ type: APP_RUNNING })
  }
  public componentDidMount() {
    this.props.dispatch({ type: USER_RELOAD_DATA })
    this.interval = setInterval(this.refreshReaderData, 30000)
  }
  public componentWillUnmount() {
    this.interval = clearInterval(this.interval)
    intercom.shutdown()
  }
  render() {
    return (
      <div className="onlineRouter">
        <Loader loading={this.props.loadingUser} />
        <Switch>
          <Route exact path="/online/cohort/:id" component={ScormReader} />
          <Route
            path="/online/"
            component={() => (
              <div>
                <Header
                  processLogout={this.processLogout}
                  premiumCompany={this.props.user.premiumCompany}
                  lang={this.props.lang}
                  toggleLang={this.toggleLang}
                  history={this.props.history}
                  user={this.props.user}
                >
                  {!this.props.loadingCohorts && (
                    <Switch>
                      <Route exact path="/online/" component={Home} />
                      <Redirect to="/online/" />
                    </Switch>
                  )}
                </Header>
              </div>
            )}
          />
        </Switch>
      </div>
    )
  }

  private toggleLang = (lang: 'en' | 'fr') => {
    this.props.dispatch({ type: USER_LANG_CHANGE, payload: { lang } })
  }
  private processLogout = () => {
    this.props.dispatch({ type: USER_LOGOUT_PROCESS })
  }
}

export default connect((store: IStore) => ({
  user: store.user.data,
  loadingUser: store.user.isRequesting,
  loadingCohorts: store.cohorts.isRequesting,
  lang: store.user.lang,
}))(OnlineRouter)
