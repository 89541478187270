import React from 'react'
import { connect } from 'react-redux'
import { IStore, IUserObject } from '../../../store/types'
import Loader from '../../../components/Loader'
import i18n from '../../../tools/i18n'
import 'react-multi-carousel/lib/styles.css'
import moment from 'moment'
import Button from '../../../components/Button'
import { Camera, Edit, FileText, Link, Mail, Plus, User, UserPlus, Users, Video, X } from 'react-feather'
import { ADMINCOHORTS_FETCH, ADMIN_FETCHAPPRENANTS, ADMIN_FETCHSUBJECTS } from '../../../store/admin/constants'
import {
  IAdminCohortLightObject,
  IApprenantAdminObject,
  ISubjectAdminObject,
  IUserAdminObject,
} from '../../../store/admin/reducer'
import Popup from 'reactjs-popup'
import AddApprenant from './add'
import ImportApprenant from './import'

interface IProps {
  navigation: any
  loading: boolean
  error: boolean
  apprenants: IUserAdminObject[]
  history: any
  lang: 'en' | 'fr'
  dispatch: (object: { type: string; payload?: any }) => void
}

class ApprenantList extends React.Component<IProps, any> {
  public componentDidMount() {
    this.props.dispatch({ type: ADMIN_FETCHAPPRENANTS })
  }
  render() {
    const { loading, apprenants, lang } = this.props
    if (loading) {
      return <Loader loading={this.props.loading} />
    }
    return (
      <div className="ahome">
        <div className="ahome__title">Gestion des apprenants</div>

        <div className="adminList__buttonContainer">
          <Popup
            trigger={
              <Button theme="primary" style={{ marginRight: 20 }}>
                <UserPlus size={24} />
                Créer un apprenant
              </Button>
            }
            modal={true}
            closeOnDocumentClick={false}
            position="top left"
          >
            {(close: () => void) => (
              <div className="adminList__popupContainer">
                <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                  <X size={24} />
                  Fermer
                </Button>
                {<AddApprenant closeForm={close} />}
              </div>
            )}
          </Popup>
          <Popup
            trigger={
              <Button theme="primary" style={{ marginRight: 20 }}>
                <Users size={24} />
                Importer des apprenants
              </Button>
            }
            modal={true}
            closeOnDocumentClick={false}
            position="top left"
          >
            {(close: () => void) => (
              <div className="adminList__popupContainer">
                <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                  <X size={24} />
                  Fermer
                </Button>
                {<ImportApprenant closeForm={close} />}
              </div>
            )}
          </Popup>
        </div>
        <div className="ahome__subtitle">{apprenants.length} apprenants</div>

        <table className="adminList__table">
          <tr>
            <th></th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th>Entreprise</th>
            <th></th>
          </tr>
          {apprenants.map((apprenant) => (
            <tr>
              <td>
                <User size={24} color="#fff" style={{ marginTop: 3 }} />
              </td>
              <td>{apprenant.lastname}</td>
              <td>{apprenant.firstname}</td>
              <td>{apprenant.email}</td>
              <td>{apprenant.company.name}</td>
              <td>
                <div className="adminList__buttonList">
                  <Button
                    onClick={() => this.goToApprenant(apprenant.id)}
                    theme="primary"
                    style={{
                      justifyContent: 'center',
                      marginRight: 15,
                      height: 30,
                      width: 30,
                      padding: 0,
                      borderRadius: '50%',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                  >
                    <Edit size={20} style={{ marginRight: 0 }} />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    )
  }
  private goToApprenant = (apprenantId: number) => {
    this.props.history.push('/online/admin/apprenant/' + apprenantId + '/')
  }
  private goToCohortAdd = () => {
    this.props.history.push('/online/admin/cohort/add')
  }
}
export default connect((store: IStore) => ({
  loading: store.admin.apprenants.requesting,
  error: store.admin.apprenants.error,
  apprenants: store.admin.apprenants.datas,
}))(ApprenantList)
