const locales: any = {
  fr: {
    errors: {
      USER_NOT_FOUND: 'Cet email est inconnu, avez vous utilisé une autre adresse ?',
      UNKNOWN_ERROR: `Une erreur inconnue s'est produite, merci de réessayer ultérieurement ou de contacter le support.`,
    },
    pages: {
      online: {
        home: {
          visiolink: 'Lien classe virtuelle',
          copylink: 'Copier le lien',
          start: 'Commencer la formation',
          continue: 'Continuer la formation',
          restart: 'Revoir la formation',
          accessto: `Accès ouvert jusqu'au`,
          ended: 'Terminé',
          startsoon: 'commence bientôt',
          now: 'En cours',
          nbcohorts: {
            zero: 'Aucune formation en cours',
            one: 'Une formation en cours',
            other: '{{count}} formations en cours',
          },
          menu: {
            btn: 'Menu',
            faq: 'Aide & FAQ',
            faqlink: 'https://fasterdesk.notion.site/Centre-d-aide-de-Fasterclass-f7f7d40b74c447ff93b4e970753bba69',
            contact: 'Contact',
            logout: 'Déconnexion',
            close: 'Fermer',
          },
        },
        scormreader: {
          menu: {
            close: 'Fermer',
          },
        },
      },
      offline: {
        login: {
          title: 'Se connecter',
          subtitle: `Saisissez votre adresse email, nous allons vous envoyer un code pour vous connecter sans mot de passe.`,
          btnTxt1: 'Recevoir le code',
          fields: {
            email: 'Email professionnel',
            code: 'Code de sécurité',
          },
          subt1: 'En poursuivant vous acceptez les',
          subt2: ` conditions d'utilisation`,
          subt3: ' de Fasterclass',
          mainError: `Email introuvable, si vous aviez un compte sur l'ancienne application Fasterclass, vous pouvez y accéder à l'adresse suivante : https://platform.fasterclass.fr`,
          mainError2: `Code incorrect`,
          subt2link: 'https://fasterdesk.notion.site/Centre-d-aide-de-Fasterclass-f7f7d40b74c447ff93b4e970753bba69',
          title2: 'Entrer le code reçu par email',
          btnTxt2: 'Valider',
          successText: 'Code valide !',
          subtitle2: `Nous avons envoyé un code à 6 chiffres à l’adresse {{email}}. Veuillez saisir le code rapidement, car il arrive bientôt à expiration.`,
          sub2: `Vous ne trouvez pas votre code ? 
          Vérifiez votre dossier de courrier indésirable / spams.`,
        },
        magic: {
          title: `Oups ! Le lien a expiré.`,
          subtitle: `Par mesure de sécurité, nous allons vous renvoyer un code de connexion.`,
          button: `Se connecter`,
        },
      },
    },
  },
  en: {
    errors: {
      USER_NOT_FOUND: 'This email is unknown, did you use another address?',
      UNKNOWN_ERROR: `An unknown error has occurred, please try again later or contact support.`,
    },
    pages: {
      online: {
        home: {
          visiolink: 'Virtual class link',
          copylink: 'Copy link',
          start: 'Start training',
          continue: 'Continue training',
          restart: 'Review training',
          accessto: `Access open until`,
          ended: 'Ended',
          startsoon: 'starts soon',
          now: 'In progress',
          nbcohorts: {
            zero: 'No ongoing training',
            one: 'One training in progress',
            other: '{{count}} ongoing training',
          },
          menu: {
            btn: 'Menu',
            faq: 'Help & FAQs',
            faqlink: 'https://fasterdesk.notion.site/Centre-d-aide-de-Fasterclass-f7f7d40b74c447ff93b4e970753bba69',
            contact: 'Contact',
            logout: 'Logout',
            close: 'Close',
          },
        },
        scormreader: {
          menu: {
            close: 'Close',
          },
        },
      },
      offline: {
        login: {
          title: 'Sign in',
          subtitle: `Enter your email address, we will send you a code to connect without password.`,
          btnTxt1: 'Receive the code',
          fields: {
            email: 'Email',
            code: 'Security code',
          },
          subt1: 'By continuing you accept the',
          subt2: ` Terms of use`,
          subt3: ' of Fasterclass',
          mainError: `Email not found, if you had an account on the old Fasterclass application, you can access it at the following address: https://platform.fasterclass.fr/`,
          mainError2: `Wrong Code`,
          subt2link: 'https://fasterdesk.notion.site/Centre-d-aide-de-Fasterclass-f7f7d40b74c447ff93b4e970753bba69',
          title2: 'Enter the code received by email',
          btnTxt2: 'Continue',
          successText: 'Code valid !',
          subtitle2: `We have sent a 6-digit code to {{email}}. Please enter the code quickly, as it is about to expire.`,
          sub2: `Can't find your code? Check your junk/spam folder.`,
        },
        magic: {
          title: `Whoops ! The link has expired.`,
          subtitle: `As a security measure, we will send you a connection code.`,
          button: `Continue`,
        },
      },
    },
  },
}
export default locales
