import React from 'react'
import { connect } from 'react-redux'
import { IStore, IUserObject } from '../../../store/types'
import Loader from '../../../components/Loader'
import i18n from '../../../tools/i18n'
import 'react-multi-carousel/lib/styles.css'
import moment from 'moment'
import Button from '../../../components/Button'
import { Camera, Edit, FileText, Link, Archive, Plus, User, UserPlus, Users, Video, X } from 'react-feather'
import {
  ADMINCOHORTS_FETCH,
  ADMIN_FETCHAPPRENANTS,
  ADMIN_FETCHSCORMS,
  ADMIN_FETCHSUBJECTS,
} from '../../../store/admin/constants'
import {
  IAdminCohortLightObject,
  IApprenantAdminObject,
  IScormAdminObject,
  ISubjectAdminObject,
  IUserAdminObject,
} from '../../../store/admin/reducer'
import Popup from 'reactjs-popup'
import AddApprenant from './add'

interface IProps {
  navigation: any
  loading: boolean
  error: boolean
  scorms: IScormAdminObject[]
  history: any
  lang: 'en' | 'fr'
  dispatch: (object: { type: string; payload?: any }) => void
}

class ScormList extends React.Component<IProps, any> {
  public componentDidMount() {
    this.props.dispatch({ type: ADMIN_FETCHSCORMS })
  }
  render() {
    const { loading, scorms, lang } = this.props
    if (loading) {
      return <Loader loading={this.props.loading} />
    }
    return (
      <div className="ahome">
        <div className="ahome__title">Gestion des packages scorm</div>

        <div className="adminList__buttonContainer">
          <Button onClick={this.goToScormAdd} theme="primary" style={{ marginRight: 20 }}>
            <Plus size={24} />
            Créer un scorm monitoré
          </Button>
        </div>
        <div className="ahome__subtitle">{scorms.length} scorms monitorés</div>

        <table className="adminList__table">
          <tr>
            <th></th>
            <th>Label</th>
            <th>Entreprise</th>
            <th>Apprenants</th>
            <th></th>
          </tr>
          {scorms.map((scorm) => (
            <tr>
              <td>
                <Archive size={24} color="#fff" style={{ marginTop: 3 }} />
              </td>
              <td>{scorm.label}</td>
              <td>{scorm.company}</td>
              <td>{scorm.nbParticipate}</td>
              <td>
                <div className="adminList__buttonList">
                  <Button
                    onClick={() => this.goToApprenant(scorm.id)}
                    theme="primary"
                    style={{
                      justifyContent: 'center',
                      marginRight: 15,
                      height: 30,
                      width: 30,
                      padding: 0,
                      borderRadius: '50%',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                  >
                    <Edit size={20} style={{ marginRight: 0 }} />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    )
  }
  private goToApprenant = (scormId: number) => {
    this.props.history.push('/online/admin/scorm/' + scormId + '/')
  }
  private goToScormAdd = () => {
    this.props.history.push('/online/admin/scorms/add')
  }
}
export default connect((store: IStore) => ({
  loading: store.admin.apprenants.requesting,
  error: store.admin.apprenants.error,
  scorms: store.admin.scorms.datas,
}))(ScormList)
