import Intercom from '../../tools/intercom'
import i18n, { getLang, setLang } from '../../tools/i18n'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  USER_SET_RATED_TRUE,
  USER_LOGOUT,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_REQUESTING,
  USER_RESET_ERROR,
  USER_UPDATE_PWD_ERROR,
  USER_UPDATE_PWD_REQUESTING,
  USER_UPDATE_PWD_SUCCESS,
  USER_UPDATE_PWD_KNOWN_ERROR,
  USER_LANG_CHANGE,
} from './constants'

export interface ICompanyObject {
  id: number
  name: string
  logo: string | null
  businessSector: string | null
  linkedinLink: string | null
}
export interface IPremiumCompanyObject {
  id: number
  name: string
  logo: string
}

export interface IUserObject {
  id: number
  firstname: string | null
  lastname: string | null
  email: string
  company: ICompanyObject | null
  premiumCompany: IPremiumCompanyObject | undefined
  isOnboarded: boolean
  userHash: string
  admin: boolean
}

export interface IUserState {
  isRequesting: boolean
  data: IUserObject
  logged: boolean
  tokenSent: boolean
  hasReadRules: boolean
  apiVersion: number | null
  loginError: string
  appRated: boolean
  error: boolean
  success: boolean
  lang: 'fr' | 'en'
}

const initialState: IUserState = {
  data: {
    email: '',
    firstname: null,
    id: 0,
    lastname: null,
    isOnboarded: false,
    company: null,
    userHash: '',
    premiumCompany: undefined,
    admin: false,
  },
  isRequesting: false,
  error: false,
  logged: false,
  loginError: '',
  tokenSent: false,
  hasReadRules: false,
  apiVersion: null,
  appRated: false,
  success: false,
  lang: getLang(),
}

const reducer = (state: IUserState = initialState, { type, payload }: { type: string; payload: any }): IUserState => {
  switch (type) {
    case USER_LANG_CHANGE:
      //i18n.locale = payload.lang
      setLang(payload.lang)

      if (state.data && state.data.id !== 0) {
        let userDatas = {
          email: state.data.email,
          name: `${state.data.firstname}`,
          user_id: `fasterclass-user-${state.data.id}`,
          user_hash: state.data.userHash,
          language_override: payload.lang,
          companies: [
            {
              name: state.data.company?.name || '',
              company_id: `company-${state.data.company?.id}` || '',
            },
          ],
        }
        Intercom.shutdown()
        Intercom.init(userDatas)
      }
      return {
        ...state,
        lang: payload.lang,
      }
    case USER_SET_RATED_TRUE:
      return {
        ...state,
        appRated: true,
      }
    case LOGIN_REQUEST:
      return {
        ...state,
        isRequesting: true,
        loginError: '',
      }
    case LOGIN_ERROR:
      return {
        ...state,
        isRequesting: false,
        loginError: payload,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: payload,
        isRequesting: false,
        loginError: '',
        logged: true,
      }
    case USER_RESET_ERROR:
      return {
        ...state,
        error: false,
        success: false,
      }
    case USER_UPDATE_ERROR:
      return {
        ...state,
        error: true,
        isRequesting: false,
        success: false,
      }
    case USER_UPDATE_REQUESTING:
      return {
        ...state,
        error: false,
        isRequesting: true,
        success: false,
      }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        data: payload,
        error: false,
        isRequesting: false,
        success: true,
      }
    case USER_UPDATE_PWD_ERROR:
      return {
        ...state,
        error: true,
        isRequesting: false,
        success: false,
      }
    case USER_UPDATE_PWD_KNOWN_ERROR:
      return {
        ...state,
        error: false,
        isRequesting: false,
        success: false,
      }
    case USER_UPDATE_PWD_REQUESTING:
      return {
        ...state,
        error: false,
        isRequesting: true,
        success: false,
      }
    case USER_UPDATE_PWD_SUCCESS:
      return {
        ...state,
        error: false,
        isRequesting: false,
        success: true,
      }
    case USER_LOGOUT:
      return {
        data: {
          email: '',
          firstname: null,
          id: 0,
          lastname: null,
          isOnboarded: false,
          company: null,
          userHash: '',
          premiumCompany: undefined,
          admin: false,
        },
        isRequesting: false,
        error: false,
        logged: false,
        loginError: '',
        tokenSent: false,
        hasReadRules: false,
        apiVersion: null,
        appRated: false,
        success: false,
        lang: getLang(),
      }
    default:
      return state
  }
}

export default reducer
