export const ADMINCOHORTS_FETCH: string = 'ADMINCOHORTS_FETCH'
export const ADMINCOHORTS_FETCH_REQUESTING: string = 'ADMINCOHORTS_FETCH_REQUESTING'
export const ADMINCOHORTS_FETCH_ERROR: string = 'ADMINCOHORTS_FETCH_ERROR'
export const ADMINCOHORTS_FETCH_SUCCESS: string = 'ADMINCOHORTS_FETCH_SUCCESS'

export const ADMINCOHORT_FETCH: string = 'ADMINCOHORT_FETCH'
export const ADMINCOHORT_FETCH_REQUESTING: string = 'ADMINCOHORT_FETCH_REQUESTING'
export const ADMINCOHORT_FETCH_ERROR: string = 'ADMINCOHORT_FETCH_ERROR'
export const ADMINCOHORT_FETCH_SUCCESS: string = 'ADMINCOHORT_FETCH_SUCCESS'

export const ADMINCOHORTS_EDITSUCCESS: string = 'ADMINCOHORTS_EDITSUCCESS'

export const ADMIN_FETCHSUBJECTS: string = 'ADMIN_FETCHSUBJECTS'
export const ADMIN_FETCHSUBJECTS_REQUESTING: string = 'ADMIN_FETCHSUBJECTS_REQUESTING'
export const ADMIN_FETCHSUBJECTS_ERROR: string = 'ADMIN_FETCHSUBJECTS_ERROR'
export const ADMIN_FETCHSUBJECTS_SUCCESS: string = 'ADMIN_FETCHSUBJECTS_SUCCESS'

export const ADMIN_DELETE_RESSOURCE: string = 'ADMIN_DELETE_RESSOURCE'
export const ADMIN_DELETE_RESSOURCE_SUCCESS: string = 'ADMIN_DELETE_RESSOURCE_SUCCESS'

export const ADMIN_DELETE_PARTICIPATE: string = 'ADMIN_DELETE_PARTICIPATE'
export const ADMIN_DELETE_PARTICIPATE_SUCCESS: string = 'ADMIN_DELETE_PARTICIPATE_SUCCESS'
export const ADMIN_DELETE_PARTICIPATE_USER: string = 'ADMIN_DELETE_PARTICIPATE_USER'
export const ADMIN_DELETE_PARTICIPATE_USER_SUCCESS: string = 'ADMIN_DELETE_PARTICIPATE_USER_SUCCESS'

export const ADMIN_FETCHAPPRENANTS: string = 'ADMIN_FETCHAPPRENANTS'
export const ADMIN_FETCHAPPRENANTS_REQUESTING: string = 'ADMIN_FETCHAPPRENANTS_REQUESTING'
export const ADMIN_FETCHAPPRENANTS_ERROR: string = 'ADMIN_FETCHAPPRENANTS_ERROR'
export const ADMIN_FETCHAPPRENANTS_SUCCESS: string = 'ADMIN_FETCHAPPRENANTS_SUCCESS'

export const ADMIN_FETCH_APPRENANT: string = 'ADMIN_FETCH_APPRENANT'
export const ADMIN_FETCH_APPRENANT_REQUESTING: string = 'ADMIN_FETCH_APPRENANT_REQUESTING'
export const ADMIN_FETCH_APPRENANT_ERROR: string = 'ADMIN_FETCH_APPRENANT_ERROR'
export const ADMIN_FETCH_APPRENANT_SUCCESS: string = 'ADMIN_FETCH_APPRENANT_SUCCESS'

export const ADMIN_SUBJECT_EDITSUCCESS: string = 'ADMIN_SUBJECT_EDITSUCCESS'

export const ADMIN_FETCHSCORMS: string = 'ADMIN_FETCHSCORMS'
export const ADMIN_FETCHSCORMS_REQUESTING: string = 'ADMIN_FETCHSCORMS_REQUESTING'
export const ADMIN_FETCHSCORMS_ERROR: string = 'ADMIN_FETCHSCORMS_ERROR'
export const ADMIN_FETCHSCORMS_SUCCESS: string = 'ADMIN_FETCHSCORMS_SUCCESS'

export const ADMIN_FETCHSCORM: string = 'ADMIN_FETCHSCORM'
export const ADMIN_FETCHSCORM_REQUESTING: string = 'ADMIN_FETCHSCORM_REQUESTING'
export const ADMIN_FETCHSCORM_ERROR: string = 'ADMIN_FETCHSCORM_ERROR'
export const ADMIN_FETCHSCORM_SUCCESS: string = 'ADMIN_FETCHSCORM_SUCCESS'

export const ADMIN_SCORM_EDITSUCCESS: string = 'ADMIN_SCORM_EDITSUCCESS'
