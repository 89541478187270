import API from '../../tools/fetch'
import { AxiosResponse } from 'axios'

export const trackingPush: (payload: any) => Promise<{}> = async (payload) => {
  try {
    /*await API.post('/private/tracker/track', {
      programId: payload.programId,
      count: payload.count,
    })*/
    return true
  } catch (error) {
    return false
  }
}
