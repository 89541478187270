import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR, USER_MAGIC } from '../../../store/user/constants'
import theme from '../../../theme'
import API from '../../../tools/fetch'
import { AxiosResponse } from 'axios'
import Form from '../../../components/Form'
import { Link } from 'react-router-dom'
import i18n from '../../../tools/i18n'
import { AlertCircle, Mail, UserCheck } from 'react-feather'
import '../login/Login.scss'
import Loader from '../../../components/Loader'
import Button from '../../../components/Button'

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void
  match: any
  history: any
}

interface IState {
  requesting: boolean
}
class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      requesting: true,
    }
  }
  async componentDidMount() {
    try {
      const request: AxiosResponse = await API.post('/auth/magic', {
        code: this.props.match.params.magiclink,
      })
      this.props.dispatch({ type: USER_MAGIC, payload: { token: request.data.token } })
    } catch (error) {
      this.setState({ requesting: false })
    }
  }

  public render(): JSX.Element | null {
    return (
      <div className="signin">
        <Loader loading={this.state.requesting} />
        <AlertCircle color={'#49FFC1'} size={38} />
        <div className="signin__title">{i18n.t('pages.offline.magic.title')}</div>
        <div className="signin__subtitle">{i18n.t('pages.offline.magic.subtitle')}</div>
        <Button onClick={this.goToLogin} theme={'primary'} style={{ width: 'auto' }}>
          {i18n.t('pages.offline.magic.button')}
        </Button>
      </div>
    )
  }
  onChangeInput = (name: string, value: string) => {
    //@ts-ignore
    this.setState({ [name]: value })
  }
  private goToLogin = () => {
    this.props.history.push('/offline/')
  }
}

export default connect((store: IStore) => ({
  loading: store.user.isRequesting,
  error: store.user.loginError,
}))(Login)
