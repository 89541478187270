import API from '../../tools/fetch'
import { IUserObject } from '../types'
import { AxiosResponse } from 'axios'

export const fetchMe: () => Promise<boolean | IUserObject> = async () => {
  try {
    const request: AxiosResponse = await API.get('/private/users/me')
    return request.data
  } catch (error) {
    return false
  }
}

export const loginAction: (payload: {
  email: string
  code: string
}) => Promise<string | { reload: boolean; token: string } | false> = async (payload) => {
  try {
    const { email, code } = payload

    const request: AxiosResponse = await API.post('/auth/login', {
      email,
      code,
    })

    return request.data
  } catch (error: any) {
    return error.response.data.code.shift() || false
  }
}

export const updateAction: (payload: any) => Promise<object | false> = async (payload) => {
  try {
    const request: AxiosResponse = await API.put('/private/users/me', payload)
    return request.data
  } catch (error) {
    return false
  }
}

export const updatePasswordAction: (payload: any) => Promise<true | Array<any>> = async (payload) => {
  try {
    await API.put('/private/users/me/password', payload)
    return true
  } catch (error: any) {
    return error.response.data.codes
  }
}
