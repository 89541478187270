const ENV: IEnvsConfig = {
  dev: {
    apiUrl: 'http://localhost:10020',
    scormApiUrl: 'http://localhost:10020',
  },
  prod: {
    apiUrl: 'https://app.fasterclass.fr/api',
    scormApiUrl: 'https://scorm.fasterclass.fr/api',
  },
}
interface IEnvsConfig {
  dev: IConfig
  prod: IConfig
}
interface IConfig {
  apiUrl: string
  scormApiUrl: string
}
export interface IConfigDev {
  apiUrl: {
    ios: string
    android: string
  }
}

export default ENV.prod
