import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR } from '../../../store/user/constants'
import theme from '../../../theme'
import API from '../../../tools/fetch'
import { AxiosResponse } from 'axios'
import Form from '../../../components/Form'
import inputs from './form2'
import inputsBasic from './form1'
import { Link } from 'react-router-dom'
import i18n from '../../../tools/i18n'
import { Mail, UserCheck } from 'react-feather'
import './Login.scss'

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void
}

interface IState {
  email: string
  requesting: boolean
  errored: boolean
  step: '1' | '2'
  success: boolean
}
class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      email: '',
      requesting: false,
      errored: false,
      step: '1',
      success: false,
    }
    this.props.dispatch({ type: LOGIN_ERROR, payload: '' })
  }

  public render(): JSX.Element | null {
    if (this.state.step === '1')
      return (
        <div className="signin">
          <UserCheck color={'#49FFC1'} size={38} />
          <div className="signin__title">{i18n.t('pages.offline.login.title')}</div>
          <div className="signin__subtitle">{i18n.t('pages.offline.login.subtitle')}</div>
          <Form
            forms={inputsBasic}
            defaultValues={[]}
            buttonText={i18n.t('pages.offline.login.btnTxt1')}
            onValidSubmit={this.onRequest}
            loading={this.state.requesting}
            mainError={this.state.errored}
            placeholder={true}
            mainErrorText={i18n.t('pages.offline.login.mainError')}
            key={'form1login'}
            //injectedErrors={this.state.injectedErrors}
          />
          <span className="signin__link">
            {i18n.t('pages.offline.login.subt1')}
            <a target="_blank" href={i18n.t('pages.offline.login.subt2link')}>
              {i18n.t('pages.offline.login.subt2')}
            </a>
            {i18n.t('pages.offline.login.subt3')}
          </span>
        </div>
      )
    return (
      <div className="signin">
        <Mail color={'#49FFC1'} size={38} />
        <div className="signin__title">{i18n.t('pages.offline.login.title2')}</div>
        <div className="signin__subtitle">{i18n.t('pages.offline.login.subtitle2', { email: this.state.email })}</div>

        <Form
          forms={inputs}
          defaultValues={[{ name: 'codeV', value: '' }]}
          buttonText={i18n.t('pages.offline.login.btnTxt2')}
          onValidSubmit={this.onProcessRequest}
          loading={this.state.requesting}
          mainError={this.state.errored}
          placeholder={true}
          mainErrorText={i18n.t('pages.offline.login.mainError2')}
          success={this.state.success}
          successText={i18n.t('pages.offline.login.successText')}
          resetOnSuccess={true}
          key={'form2login'}
          //injectedErrors={this.state.injectedErrors}
        />

        <span className="signin__link">{i18n.t('pages.offline.login.sub2')}</span>
      </div>
    )
  }
  private onRequest = (values: { [key: string]: string }) => {
    this.setState({ requesting: true, errored: false, email: values.email }, async () => {
      try {
        const request: AxiosResponse = await API.post('/auth/loginCode', {
          email: this.state.email,
        })
        this.setState({ requesting: false, step: '2' })
      } catch (error) {
        this.setState({ requesting: false, errored: true })
      }
    })
  }
  private onProcessRequest = (values: { [key: string]: string }) => {
    this.setState({ requesting: true, errored: false }, async () => {
      try {
        this.props.dispatch({ type: LOGIN, payload: { email: this.state.email, code: values.codeV } })
      } catch (error) {
        this.setState({ requesting: false, errored: true })
      }
    })
  }
  onChangeInput = (name: string, value: string) => {
    //@ts-ignore
    this.setState({ [name]: value })
  }
}

export default connect((store: IStore) => ({
  loading: store.user.isRequesting,
  error: store.user.loginError,
}))(Login)
