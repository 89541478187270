import API from '../../tools/fetch'
import scormAPI from '../../tools/fetch/scormApi'
import { AxiosResponse } from 'axios'
import { IAdminCohortLightObject } from './reducer'

export const cohortsFetchAction: any = async (payload: { apprenantId: number }) => {
  try {
    let request = false
    if (payload && payload.apprenantId) {
      request = await API.get('/admin/cohorts/list', {
        params: { apprenantId: payload.apprenantId },
      })
    } else {
      request = await API.get('/admin/cohorts/list')
    }
    // @ts-ignore
    return request.data && request.data
  } catch (error) {
    return false
  }
}
export const subjectsFetchAction: any = async () => {
  try {
    const request: AxiosResponse = await API.get('/admin/subjects/list')
    return request.data
  } catch (error) {
    return false
  }
}
export const apprenantsFetchAction: any = async (payload: { cohortId: number }) => {
  try {
    let request = false
    if (payload && payload.cohortId) {
      request = await API.get('/admin/apprenants/list', {
        params: { cohortId: payload.cohortId },
      })
    } else {
      request = await API.get('/admin/apprenants/list')
    }
    // @ts-ignore
    return request.data && request.data
  } catch (error) {
    return false
  }
}

export const subjectFetchAction: any = async (payload: { subjectId: number }) => {
  try {
    const request: AxiosResponse = await API.get('/admin/subjects/get/' + payload.subjectId)
    return request.data
  } catch (error) {
    console.log(error)
    return false
  }
}
export const cohortFetchAction: any = async (payload: { cohortId: number }) => {
  try {
    const request: AxiosResponse = await API.get('/admin/cohorts/get/' + payload.cohortId)
    return request.data
  } catch (error) {
    console.log(error)
    return false
  }
}
export const scormsFetchAction: any = async (payload: {}) => {
  try {
    const request: AxiosResponse = await scormAPI.get('/admin/scorms/list')
    return request.data
  } catch (error) {
    console.log(error)
    return false
  }
}
export const scormFetchAction: any = async (payload: { scormId: number }) => {
  try {
    const request: AxiosResponse = await scormAPI.get('/admin/scorms/get/' + payload.scormId)
    return request.data
  } catch (error) {
    console.log(error)
    return false
  }
}
export const cohortProgressAction: any = async (payload: { cohortId: number; percent: number }) => {
  try {
    const request: AxiosResponse = await API.put('/private/cohorts/progress', payload)
    return request.data
  } catch (error) {
    return false
  }
}

export const deleteRessourceAction: any = async (payload: { cohortId: number }) => {
  try {
    const request: AxiosResponse = await API.delete('/admin/cohorts/ressource/' + payload)
    return request.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export const deleteParticipateAction: any = async (payload: { cohortId: number; participateId: number }) => {
  try {
    const request: AxiosResponse = await API.delete(
      '/admin/cohorts/' + payload.cohortId + '/participate/' + payload.participateId,
    )
    return request.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export const apprenantDetailAction: any = async (payload: { apprenantId: number }) => {
  try {
    const request: AxiosResponse = await API.get('/admin/apprenants/get/' + payload.apprenantId)
    return request.data
  } catch (error) {
    console.log(error)
    return false
  }
}
