import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import GreenLight from '../../../components/Texts/GreenLight'
import Button from '../../../components/Button'
import {
  Activity,
  ArrowLeft,
  ChevronLeft,
  Crosshair,
  Download,
  Edit,
  Eye,
  Mail,
  Plus,
  Trash2,
  User,
  UserPlus,
  X,
} from 'react-feather'
import theme from '../../../theme'
import GreyUpSpace from '../../../components/Texts/GreyUpScale'
import ActionCard from '../../../components/ActionCard'
import i18n from '../../../tools/i18n'
import API from '../../../tools/fetch'
import Loader from '../../../components/Loader'
import { ICohortObject } from '../../../store/cohorts/reducer'
import Header from './Header/Header'
import config from '../../../config'
import { cohortsFetchAction } from '../../../store/cohorts/actions'
import { IUserObject } from '../../../store/types'
import 'scorm-again'
import EditCohort from './edit'
import AddRessource from './addRessource'
import { COHORT_PROGRESS } from '../../../store/cohorts/constants'
import { IAdminCohortObject } from '../../../store/admin/reducer'
import { ADMINCOHORT_FETCH, ADMIN_DELETE_PARTICIPATE, ADMIN_DELETE_RESSOURCE } from '../../../store/admin/constants'
import moment from 'moment'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import AddApprenant from './addApprenant'
import EditApprenant from './editApprenant'

interface IProps {
  match: any
  cohort?: IAdminCohortObject
  requesting: boolean
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  history: any
  lang: 'fr' | 'en'
  cohortId: number
  user: IUserObject
}
interface IState {}
class CohortAdmin extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {
    this.props.dispatch({ type: ADMINCOHORT_FETCH, payload: { cohortId: this.props.cohortId } })
  }
  public render(): JSX.Element | null {
    const { lang, cohort, history, requesting } = this.props
    const {} = this.state
    return (
      <div style={{ position: 'relative' }}>
        <Loader loading={this.props.requesting} insert={true} />
        {!requesting && cohort !== undefined ? (
          <div className="adminList__dataContainer">
            <div className="adminList__buttonContainer">
              <Button onClick={this.returnList} theme="greyLight" style={{ marginRight: 20 }}>
                <ChevronLeft size={24} />
                Retour
              </Button>
              <Popup
                trigger={
                  <Button theme="primary" style={{ marginRight: 20 }}>
                    <Edit size={24} />
                    Modifier
                  </Button>
                }
                modal={true}
                closeOnDocumentClick={false}
                position="top left"
              >
                {(close: () => void) => (
                  <div className="adminList__popupContainer">
                    <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                      <X size={24} />
                      Fermer
                    </Button>
                    {
                      // @ts-ignore
                      <EditCohort closeForm={close} cohort={this.props.cohort} />
                    }
                  </div>
                )}
              </Popup>
              <Button onClick={() => this.openSuivi()} theme="primary">
                <Activity size={24} />
                Suivi avancé
              </Button>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Label</div>
              <div className="adminList__dataValue">{cohort.label}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Sujet</div>
              <div className="adminList__dataValue">{cohort.subject.titre}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Date de début</div>
              <div className="adminList__dataValue">{moment(cohort.startDate).format('DD/MM/YYYY')}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Date de fin d'accès</div>
              <div className="adminList__dataValue">{moment(cohort.endAccesDate).format('DD/MM/YYYY')}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Scorm</div>
              <div className="adminList__dataValue">{cohort.scorm}</div>
            </div>
            <div className="adminList__data">
              <div className="adminList__dataLabel">Pour les partenaires</div>
              <div className="adminList__dataValue">{cohort.forPartenaire ? 'Oui' : 'Non'}</div>
            </div>
            <div className="adminList__dataDivider" />
            <div className="adminList__data">
              <div className="adminList__buttonContainer">
                <div className="adminList__dataLabel">Ressources</div>

                <Popup
                  trigger={
                    <Button
                      theme="primary"
                      style={{
                        justifyContent: 'center',
                        marginLeft: 15,
                        height: 40,
                        width: 40,
                        padding: 0,
                        borderRadius: '50%',
                        textAlign: 'center',
                        display: 'flex',
                      }}
                    >
                      <Plus size={24} style={{ marginRight: 0 }} />
                    </Button>
                  }
                  modal={true}
                  closeOnDocumentClick={false}
                  position="top left"
                >
                  {(close: () => void) => (
                    <div className="adminList__popupContainer">
                      <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                        <X size={24} />
                        Fermer
                      </Button>
                      {
                        // @ts-ignore
                        <AddRessource closeForm={close} cohort={this.props.cohort} />
                      }
                    </div>
                  )}
                </Popup>
              </div>
              <div className="adminList__imgCard">
                {cohort.ressources.map((ressource) => (
                  <div className="adminList__imgCardContainer">
                    <div className="adminList__imgCardImg">
                      <img src={ressource.image} />
                    </div>
                    <div className="adminList__imgCardText">{lang === 'fr' ? ressource.title : ressource.fileEn}</div>
                    <div className="adminList__imgCardBtn">
                      <Button
                        onClick={() => this.openRessource(lang === 'fr' ? ressource.file : ressource.fileEn)}
                        theme="primary"
                        style={{
                          justifyContent: 'center',
                          marginRight: 15,
                          height: 40,
                          width: 40,
                          padding: 0,
                          borderRadius: '50%',
                          textAlign: 'center',
                          display: 'flex',
                        }}
                      >
                        <Download size={24} style={{ marginRight: 0 }} />
                      </Button>

                      <Popup
                        trigger={
                          <Button
                            theme="error"
                            style={{
                              justifyContent: 'center',
                              marginRight: 85,
                              marginTop: 15,
                              height: 40,
                              width: 40,
                              padding: 0,
                              borderRadius: '50%',
                              textAlign: 'center',
                              display: 'flex',
                            }}
                          >
                            <Trash2 size={24} style={{ marginRight: 0 }} />
                          </Button>
                        }
                        modal={true}
                        closeOnDocumentClick={false}
                        position="top left"
                      >
                        {(close: () => void) => (
                          <div
                            className="adminList__popupContainer"
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <h3>Etes vous sur de vouloir supprimer cette ressource : </h3>
                            <div className="adminList__imgCardContainer">
                              <div className="adminList__imgCardImg">
                                <img src={ressource.image} />
                              </div>
                              <div className="adminList__imgCardText">
                                {lang === 'fr' ? ressource.title : ressource.fileEn}
                              </div>
                              <div className="adminList__imgCardBtn"></div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                flex: 1,
                              }}
                            >
                              <Button
                                onClick={() => this.deleteRessource(ressource.id, close)}
                                theme="error"
                                style={{ marginBottom: 20 }}
                              >
                                <Trash2 size={24} />
                                Supprimer
                              </Button>
                              <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                                <X size={24} />
                                Annuler
                              </Button>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="adminList__dataDivider" />
            <div className="adminList__data">
              <div className="adminList__buttonContainer">
                <div className="adminList__dataLabel">Apprenants</div>

                <Popup
                  trigger={
                    <Button
                      theme="primary"
                      style={{
                        justifyContent: 'center',
                        marginLeft: 15,
                        height: 40,
                        width: 40,
                        padding: 0,
                        borderRadius: '50%',
                        textAlign: 'center',
                        display: 'flex',
                      }}
                    >
                      <UserPlus size={24} style={{ marginRight: 0 }} />
                    </Button>
                  }
                  modal={true}
                  closeOnDocumentClick={false}
                  position="top left"
                >
                  {(close: () => void) => (
                    <div className="adminList__popupContainer">
                      {
                        // @ts-ignore
                        <AddApprenant closeForm={close} cohort={this.props.cohort} />
                      }
                    </div>
                  )}
                </Popup>
              </div>
              {cohort.participates.length > 0 && (
                <table className="adminList__table">
                  <tr>
                    <th></th>
                    <th style={{ whiteSpace: 'nowrap' }}>Fin accès</th>
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Email</th>
                    <th>Entreprise</th>
                    <th></th>
                  </tr>
                  {cohort.participates.map((apprenant) => (
                    <tr>
                      <td>
                        <span
                          style={{
                            backgroundColor:
                              apprenant.completion === 0
                                ? `${theme.colors.greys.lighter}`
                                : apprenant.completion === 100
                                ? `${theme.colors.primary.light}65`
                                : `${theme.colors.error}65`,

                            borderRadius: 10,
                            padding: '2px 10px',
                            fontSize: 14,
                            lineHeight: '27px',
                          }}
                        >
                          {apprenant.completion}%
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            fontSize: 16,
                            lineHeight: '27px',
                            textDecoration: moment(apprenant.endDate) < moment() ? 'line-through' : 'none',
                            color:
                              moment(apprenant.endDate) < moment()
                                ? theme.colors.greys.lighter
                                : moment(apprenant.endDate) < moment().add('1', 'weeks')
                                ? theme.colors.error
                                : theme.colors.white,
                          }}
                        >
                          {moment(apprenant.endDate).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: 16, lineHeight: '27px', whiteSpace: 'nowrap' }}>
                          {apprenant.user.lastname}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: 16, lineHeight: '27px', whiteSpace: 'nowrap' }}>
                          {apprenant.user.firstname}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: 16, lineHeight: '27px', whiteSpace: 'nowrap' }}>
                          {apprenant.user.email}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontSize: 16, lineHeight: '27px', whiteSpace: 'nowrap' }}>
                          {apprenant.user.company.name}
                        </span>
                      </td>
                      <td>
                        <div className="adminList__buttonList">
                          <Popup
                            trigger={
                              <Button
                                theme="primary"
                                style={{
                                  justifyContent: 'center',
                                  marginRight: 15,
                                  height: 30,
                                  width: 30,
                                  padding: 0,
                                  borderRadius: '50%',
                                  textAlign: 'center',
                                  display: 'flex',
                                }}
                              >
                                <Edit size={20} style={{ marginRight: 0 }} />
                              </Button>
                            }
                            modal={true}
                            closeOnDocumentClick={false}
                            position="top left"
                          >
                            {(close: () => void) => (
                              <div className="adminList__popupContainer">
                                {
                                  <EditApprenant
                                    apprenant={apprenant.user}
                                    closeForm={close}
                                    // @ts-ignore
                                    cohort={this.props.cohort}
                                    startDate={apprenant.startDate}
                                    endDate={apprenant.endDate}
                                  />
                                }
                              </div>
                            )}
                          </Popup>
                          <Button
                            onClick={() => this.goToApprenant(apprenant.user.id)}
                            theme="primary"
                            style={{
                              justifyContent: 'center',
                              marginRight: 15,
                              height: 30,
                              width: 30,
                              padding: 0,
                              borderRadius: '50%',
                              textAlign: 'center',
                              display: 'flex',
                            }}
                          >
                            <Eye size={20} style={{ marginRight: 0 }} />
                          </Button>
                          <Popup
                            trigger={
                              <Button
                                theme="error"
                                style={{
                                  justifyContent: 'center',
                                  marginRight: 85,
                                  height: 30,
                                  width: 30,
                                  padding: 0,
                                  borderRadius: '50%',
                                  textAlign: 'center',
                                  display: 'flex',
                                }}
                              >
                                <X size={20} style={{ marginRight: 0 }} />
                              </Button>
                            }
                            modal={true}
                            closeOnDocumentClick={false}
                            position="top left"
                          >
                            {(close: () => void) => (
                              <div
                                className="adminList__popupContainer"
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <h3>
                                  Etes vous sur de vouloir désinscrire {apprenant.user.lastname}{' '}
                                  {apprenant.user.firstname} de cette cohorte ?{' '}
                                </h3>

                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                  }}
                                >
                                  <Button
                                    onClick={() => this.deleteParticipate(apprenant.id, close)}
                                    theme="error"
                                    style={{ marginBottom: 20 }}
                                  >
                                    <Trash2 size={24} />
                                    Désinscrire
                                  </Button>
                                  <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                                    <X size={24} />
                                    Annuler
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Popup>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
  private returnList = () => {
    this.props.history.push('/online/admin/')
  }
  private openRessource = (url: string) => {
    window.open(url, '_blank', 'noreferrer')
  }
  private deleteRessource = (ressourceId: number, close: () => void) => {
    this.props.dispatch({ type: ADMIN_DELETE_RESSOURCE, payload: ressourceId })
    close()
  }
  private deleteParticipate = (participateId: number, close: () => void) => {
    this.props.dispatch({ type: ADMIN_DELETE_PARTICIPATE, payload: { participateId, cohortId: this.props.cohortId } })
    close()
  }

  private goToApprenant = (apprenantId: number) => {
    this.props.history.push('/online/admin/apprenant/' + apprenantId + '/')
  }
  private openSuivi = () => {
    window.open(this.props.cohort?.externTracker, '_blank', 'noreferrer')
  }
}

export default connect((store: IStore, props: IProps) => {
  return {
    cohortId: props.match.params.id,
    cohort: store.admin.cohort.datas,
    error: store.admin.cohort.error,
    requesting: store.admin.cohort.requesting,
    lang: store.user.lang,
    user: store.user.data,
  }
})(CohortAdmin)
