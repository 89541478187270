import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import {
  COHORTS_FETCH,
  COHORTS_FETCH_SUCCESS,
  COHORTS_FETCH_REQUESTING,
  COHORTS_FETCH_ERROR,
  COHORT_PROGRESS,
} from './constants'
import { cohortsFetchAction, cohortProgressAction } from './actions'
import { ICohortObject } from './reducer'
import { IStore } from '..'
import { ACTIONS_FETCH } from '../actions/constants'

export default function* userWatcher(): IterableIterator<any> {
  yield takeLatest([COHORTS_FETCH], cohortsFetch)
  return yield takeLatest([COHORT_PROGRESS], cohortProgress)
}

function* cohortsFetch({ payload }: any): any {
  yield put({ type: COHORTS_FETCH_REQUESTING })
  const data: ICohortObject[] | false = yield call(cohortsFetchAction, payload)
  if (!data) {
    return yield put({
      type: COHORTS_FETCH_ERROR,
    })
  }
  return yield put({
    type: COHORTS_FETCH_SUCCESS,
    payload: data,
  })
}

function* cohortProgress({ payload }: any): any {
  console.log('progress', payload.percent)
  return yield call(cohortProgressAction, payload)
}
