import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import { LOGIN, LOGIN_ERROR } from '../../../store/user/constants'
import theme from '../../../theme'
import API from '../../../tools/fetch'
import { AxiosResponse } from 'axios'
import Form, { IFormObject, IFormValue } from '../../../components/Form'
import { Link } from 'react-router-dom'
import i18n from '../../../tools/i18n'
import { Mail, UserCheck, UserPlus, X } from 'react-feather'
import { IAdminCohortObject } from '../../../store/admin/reducer'
import { ADMINCOHORTS_EDITSUCCESS, ADMINCOHORTS_FETCH } from '../../../store/admin/constants'
import Button from '../../../components/Button'

const inputs: IFormObject[] = [
  {
    name: 'apprenant',
    title: '::Apprenant',
    type: 'apprenantSelector',
    half: false,
    validation: { presence: { allowEmpty: false, message: 'NONE' } },
  },
  {
    name: 'startDate',
    title: `::Date de début (optionnelle)`,
    type: 'date',
    half: false,
    validation: { presence: { allowEmpty: true, message: 'NONE' } },
  },
  {
    name: 'endDate',
    title: `::Date de fin d'accès (optionnelle)`,
    type: 'date',
    half: false,
    validation: { presence: { allowEmpty: true, message: 'NONE' } },
  },
]

interface IProps {
  dispatch: (object: { type: string; payload: any }) => void
  cohort: IAdminCohortObject
  closeForm: () => void
}

interface IState {
  requesting: boolean
  errored: boolean
  success: boolean
  //dv: IFormValue[]
}
class AddApprenant extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    /*let dv: IFormValue[] = []
    Object.keys(this.props.cohort).forEach((key) => {
      dv.push({
        name: key,
        // @ts-ignore
        value: this.props.cohort[key],
      })
    })*/
    this.state = {
      requesting: false,
      errored: false,
      success: false,
      //dv: dv,
    }
  }

  public render(): JSX.Element | null {
    return (
      <div>
        <div className="adminList__buttonContainer">
          <Button onClick={this.props.closeForm} theme="greyLight" style={{ marginBottom: 20 }}>
            <X size={24} />
            Fermer
          </Button>
          <Button onClick={() => {}} theme="primary" style={{ marginBottom: 20, marginLeft: 15 }}>
            <UserPlus size={24} />
            Créer un apprenant
          </Button>
        </div>
        <Form
          forms={inputs}
          buttonText={`Ajouter à la cohorte`}
          onValidSubmit={this.onRequest}
          loading={this.state.requesting}
          mainError={this.state.errored}
          placeholder={true}
          label={true}
          mainErrorText={'Impossible de se connecter au serveur.'}
          key={'form1editcohort'}
          //injectedErrors={this.state.injectedErrors}
        />
      </div>
    )
  }
  private onRequest = (values: { [key: string]: any }) => {
    this.setState({ requesting: true, errored: false }, async () => {
      try {
        const request: AxiosResponse = await API.post(
          '/admin/cohorts/' + this.props.cohort.id + '/addapprenant/' + values['apprenant']['id'],
          {
            startDate: values['startDate'],
            endDate: values['endDate'],
          },
        )

        this.props.dispatch({ type: ADMINCOHORTS_EDITSUCCESS, payload: request.data })

        this.setState({ requesting: false, errored: false }, () => setTimeout(() => this.props.closeForm(), 500))
      } catch (error) {
        this.setState({ requesting: false, errored: true })
      }
    })
  }

  onChangeInput = (name: string, value: string) => {
    //@ts-ignore
    this.setState({ [name]: value })
  }
}

export default connect((store: IStore) => ({}))(AddApprenant)
