import React from 'react'
import { connect } from 'react-redux'
import { IStore, IUserObject } from '../../../store/types'
import Loader from '../../../components/Loader'
import i18n from '../../../tools/i18n'
import 'react-multi-carousel/lib/styles.css'
import moment from 'moment'
import Button from '../../../components/Button'
import { Camera, FileText, Link, Plus, Users, Video, X } from 'react-feather'
import { ADMINCOHORTS_FETCH, ADMIN_FETCHSUBJECTS } from '../../../store/admin/constants'
import { IAdminCohortLightObject, ISubjectAdminObject } from '../../../store/admin/reducer'
import Popup from 'reactjs-popup'
import EditSubject from './edit'
import AddSubject from './add'

interface IProps {
  navigation: any
  loading: boolean
  error: boolean
  subjects: ISubjectAdminObject[]
  history: any
  user: IUserObject
  lang: 'en' | 'fr'
  dispatch: (object: { type: string; payload?: any }) => void
}

class SubjectList extends React.Component<IProps, any> {
  public componentDidMount() {
    this.props.dispatch({ type: ADMIN_FETCHSUBJECTS })
  }
  render() {
    const { loading, subjects, lang, user } = this.props
    if (loading) {
      return <Loader loading={this.props.loading} />
    }
    return (
      <div className="ahome">
        <div className="ahome__title">Gestion des sujets</div>

        <div className="adminList__buttonContainer">
          <Popup
            trigger={
              <Button theme="primary" style={{ marginRight: 20 }}>
                <Plus size={24} />
                Créer un sujet
              </Button>
            }
            modal={true}
            closeOnDocumentClick={false}
            position="top left"
          >
            {(close: () => void) => (
              <div className="adminList__popupContainer">
                <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                  <X size={24} />
                  Fermer
                </Button>
                {<AddSubject closeForm={close} />}
              </div>
            )}
          </Popup>
        </div>
        <div className="ahome__subtitle">{subjects.length} sujets</div>
        {subjects.map((subject) => (
          <div className="ahome__cohort" key={`home-cohorts-${subject.id}`}>
            <div className="ahome__cohortSubContainer" style={{ backgroundImage: 'url(' + subject.image + ')' }}>
              <div className="ahome__cohortContainer">
                <div className="ahome__cohortImgContainer" style={{ backgroundImage: 'url(' + subject.image + ')' }}>
                  <img src={lang === 'fr' ? subject.logo : subject.logo} />
                </div>
                <div className="ahome__cohortOtherContainer">
                  <div className="ahome__cohortOtherTop">
                    <div className="ahome__cohortOtherTopLeft">
                      <div className="ahome__cohortOtherTopLeftT1">
                        {lang === 'fr' ? subject.titre : subject.titreEn}
                      </div>
                      <div className="ahome__cohortOtherTopLeftT2">
                        {lang === 'fr' ? subject.description : subject.descriptionEn}
                      </div>
                      <div className="ahome__cohortOtherTopLeftT3"></div>
                    </div>
                    <div className="ahome__cohortOtherTopRight">
                      <Popup
                        trigger={<Button>Modifier</Button>}
                        modal={true}
                        closeOnDocumentClick={false}
                        position="top left"
                      >
                        {(close: () => void) => (
                          <div className="adminList__popupContainer">
                            <Button onClick={close} theme="greyLight" style={{ marginBottom: 20 }}>
                              <X size={24} />
                              Fermer
                            </Button>
                            {<EditSubject closeForm={close} subject={subject} />}
                          </div>
                        )}
                      </Popup>
                    </div>
                  </div>
                  <div className="ahome__cohortOtherBottom">
                    <div className="ahome__cohortOtherBottomLeft">
                      <div className="ahome__cohortOtherBottomLeftT1"></div>
                      <div className="ahome__cohortOtherBottomLeftT2"></div>
                    </div>
                    <div className="ahome__cohortOtherBottomRight"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
  private goToCohort = (cohortId: number) => {
    this.props.history.push('/online/admin/cohort/' + cohortId + '/')
  }
  private goToCohortAdd = () => {
    this.props.history.push('/online/admin/cohort/add')
  }
}
export default connect((store: IStore) => ({
  loading: store.admin.subjects.requesting,
  error: store.admin.subjects.error,
  subjects: store.admin.subjects.datas,
}))(SubjectList)
