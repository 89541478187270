import React from 'react'
import { connect } from 'react-redux'
import { IStore, IUserObject } from '../../../store/types'
import Loader from '../../../components/Loader'
import './Home.scss'
import i18n from '../../../tools/i18n'
import 'react-multi-carousel/lib/styles.css'
import moment from 'moment'
import Button from '../../../components/Button'
import { Camera, FileText, Link, Plus, Users, Video } from 'react-feather'
import { ADMINCOHORTS_FETCH } from '../../../store/admin/constants'
import { IAdminCohortLightObject } from '../../../store/admin/reducer'

interface IProps {
  navigation: any
  loading: boolean
  error: boolean
  cohorts: IAdminCohortLightObject[]
  history: any
  user: IUserObject
  lang: 'en' | 'fr'
  dispatch: (object: { type: string; payload?: any }) => void
}

class Home extends React.Component<IProps, any> {
  public componentDidMount() {
    console.log('hehehehe')
    this.props.dispatch({ type: ADMINCOHORTS_FETCH })
  }
  render() {
    const { loading, cohorts, lang, user } = this.props
    if (loading) {
      return <Loader loading={this.props.loading} />
    }
    return (
      <div className="ahome">
        <div className="ahome__title">Gestion des cohortes</div>

        <div className="adminList__buttonContainer">
          <Button onClick={this.goToCohortAdd} theme="primary" style={{ marginRight: 20 }}>
            <Plus size={24} />
            Créer une cohorte
          </Button>
        </div>
        <div className="ahome__subtitle">{cohorts.length} cohortes en cours</div>
        {cohorts.map((cohort) => (
          <div className="ahome__cohort" key={`home-cohorts-${cohort.id}`}>
            <div className="ahome__cohortSubContainer" style={{ backgroundImage: 'url(' + cohort.subject.image + ')' }}>
              <div className="ahome__cohortContainer">
                <div
                  className="ahome__cohortImgContainer"
                  style={{ backgroundImage: 'url(' + cohort.subject.image + ')' }}
                >
                  <img src={lang === 'fr' ? cohort.subject.logo : cohort.subject.logoEn} />
                </div>
                <div className="ahome__cohortOtherContainer">
                  <div className="ahome__cohortOtherTop">
                    <div className="ahome__cohortOtherTopLeft">
                      <div className="ahome__cohortOtherTopLeftT0">{lang === 'fr' ? cohort.label : cohort.labelEn}</div>
                      <div className="ahome__cohortOtherTopLeftT1">
                        {lang === 'fr' ? cohort.subject.titre : cohort.subject.titreEn}
                      </div>
                      <div className="ahome__cohortOtherTopLeftT2">
                        {lang === 'fr' ? cohort.subject.description : cohort.subject.descriptionEn}
                      </div>
                      <div className="ahome__cohortOtherTopLeftT3"></div>
                    </div>
                    <div className="ahome__cohortOtherTopRight">
                      <Button onClick={() => this.goToCohort(cohort.id)}>Gérer</Button>
                    </div>
                  </div>
                  <div className="ahome__cohortOtherBottom">
                    <div className="ahome__cohortOtherBottomLeft">
                      <div className="ahome__cohortOtherBottomLeftT1"></div>
                      <div className="ahome__cohortOtherBottomLeftT2"></div>
                    </div>
                    <div className="ahome__cohortOtherBottomRight"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ahome__cohortVisioLink">
              <div className="ahome__cohortVisioLinkTitle">
                <Users size={24} color={'#26202D'} /> {cohort.participates} Participants
              </div>
              <div className="ahome__cohortVisioLinkTitle">
                <FileText size={24} color={'#26202D'} /> {cohort.ressources} Ressources
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
  private goToCohort = (cohortId: number) => {
    this.props.history.push('/online/admin/cohort/' + cohortId + '/')
  }
  private goToCohortAdd = () => {
    this.props.history.push('/online/admin/cohort/add')
  }
}
export default connect((store: IStore) => ({
  loading: store.admin.cohorts.requesting,
  error: store.admin.cohorts.error,
  cohorts: store.admin.cohorts.datas,
}))(Home)
