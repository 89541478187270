import { persistor, store } from './store'
import { Provider } from 'react-redux'
import PagesRouter from './pages/Router'
import { initToken } from './tools/token'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserView, MobileView } from 'react-device-detect'
import MobileDisplay from './components/MobileDisplay'
import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import history from './tools/history'

function App() {
  initToken()
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            render={() => {
              return (
                <div>
                  <MobileView>
                    <PagesRouter />
                  </MobileView>
                  <BrowserView>
                    <PagesRouter />
                  </BrowserView>
                </div>
              )
            }}
          />
          <Redirect to="/offline/" />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
