import API from '../../tools/fetch'
import { AxiosResponse } from 'axios'
import { ICohortObject } from './reducer'

export const cohortsFetchAction: any = async () => {
  try {
    const request: AxiosResponse = await API.get('/private/cohorts/home')
    return request.data
  } catch (error) {
    return false
  }
}
export const cohortProgressAction: any = async (payload: { cohortId: number; percent: number }) => {
  try {
    const request: AxiosResponse = await API.put('/private/cohorts/progress', payload)
    return request.data
  } catch (error) {
    return false
  }
}
