import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, Link } from 'react-router-dom'

import { IStore } from '../../store'
import { APP_RUNNING } from '../../store/trackEvent/constants'
import { IUserObject } from '../../store/types'
import { USER_LANG_CHANGE, USER_LOGOUT_PROCESS, USER_RELOAD_DATA } from '../../store/user/constants'

import Home from './Home'
import Settings from './Settings'
import './AdminRouter.scss'
import ChangePassword from './ChangePassword'
import intercom from '../../tools/intercom'
import Loader from '../../components/Loader'
import CohortAdmin from './CohortAdmin'
import { Sidebar, ThumbsUp, Users, Clipboard, Archive } from 'react-feather'
import theme from '../../theme'
import i18n from '../../tools/i18n'
import Logo from '../../assets/images/logo_fasterclass.png'
import AddCohortAdmin from './AddCohortAdmin'
import SubjectList from './SubjectList'
import ApprenantList from './ApprenantList'
import ApprenantAdmin from './ApprenantAdmin'
import ScormList from './ScormList'
import ScormAdmin from './ScormAdmin'
import AddScormAdmin from './AddScormAdmin'

interface IProps {
  user: IUserObject
  dispatch: (props: { type: string; payload?: any }) => void
  loadingUser: boolean
  lang: 'en' | 'fr'
}

class AdminRouter extends React.Component<IProps> {
  private interval: any = undefined

  private refreshReaderData = () => {
    this.props.dispatch({ type: APP_RUNNING })
  }
  public componentDidMount() {
    this.props.dispatch({ type: USER_RELOAD_DATA })
    intercom.shutdown()
  }
  public componentWillUnmount() {
    intercom.shutdown()
  }
  render() {
    return (
      <div className="adminRouter">
        <Loader loading={this.props.loadingUser} />
        <Switch>
          <Route
            path="/online/admin/"
            component={() => (
              <div>
                <div className="adminRouter__adminContainer">
                  <div className="adminRouter__leftMenu">
                    <div className="adminRouter__logo">
                      <img src={Logo} alt="logo" />
                    </div>
                    <div className="adminRouter__menuContent">
                      <Link className="adminRouter__menuContentoption" to="/online/admin/">
                        <Sidebar color={theme.colors.white} size={18} />
                        <span>Cohortes</span>
                      </Link>
                      <Link className="adminRouter__menuContentoption" to="/online/admin/users/list">
                        <Users color={theme.colors.white} size={18} />
                        <span>Apprenants</span>
                      </Link>
                      <Link className="adminRouter__menuContentoption" to="/online/admin/subjects/list">
                        <Clipboard color={theme.colors.white} size={18} />
                        <span>Sujets des cohortes</span>
                      </Link>
                      <Link className="adminRouter__menuContentoption" to="/online/admin/scorms/list">
                        <Archive color={theme.colors.white} size={18} />
                        <span>Scorms monitorés</span>
                      </Link>
                    </div>
                  </div>
                  <div className="adminRouter__mainContent">
                    {this.props.loadingUser && this.props.user ? (
                      <div />
                    ) : (
                      <Switch>
                        <Route exact path="/online/admin/cohort/add" component={AddCohortAdmin} />
                        <Route exact path="/online/admin/cohort/:id" component={CohortAdmin} />
                        <Route exact path="/online/admin/apprenant/:id" component={ApprenantAdmin} />
                        <Route exact path="/online/admin/subjects/list" component={SubjectList} />
                        <Route exact path="/online/admin/users/list" component={ApprenantList} />
                        <Route exact path="/online/admin/scorms/list" component={ScormList} />
                        <Route exact path="/online/admin/scorms/add" component={AddScormAdmin} />
                        <Route exact path="/online/admin/scorm/:id" component={ScormAdmin} />
                        <Route exact path="/online/admin/" component={Home} />
                        <Route exact path="/online/admin/settings" component={Settings} />
                        <Route exact path="/online/admin/password" component={ChangePassword} />
                        <Redirect to="/online/admin/" />
                      </Switch>
                    )}
                  </div>
                </div>
              </div>
            )}
          />
        </Switch>
      </div>
    )
  }

  private toggleLang = (lang: 'en' | 'fr') => {
    this.props.dispatch({ type: USER_LANG_CHANGE, payload: { lang } })
  }
  private processLogout = () => {
    this.props.dispatch({ type: USER_LOGOUT_PROCESS })
  }
}

export default connect((store: IStore) => ({
  user: store.user.data,
  loadingUser: store.user.isRequesting,
  lang: store.user.lang,
}))(AdminRouter)
