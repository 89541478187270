import React, { CSSProperties } from 'react'
import { CheckSquare, Square } from 'react-feather'
import theme from '../../theme'
import i18n from '../../tools/i18n'

interface IProps {
  value: string
  onChange: (name: string, value: string) => void
  name: string
  style?: CSSProperties
  containerStyle?: CSSProperties
  locked?: boolean
  lang?: 'fr' | 'en'
}
interface IState {
  secure: boolean
}
export default class Boolean extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { secure: true }
  }

  public render(): JSX.Element {
    const { value, style, containerStyle, name, locked } = this.props
    return (
      <div style={{ ...styles.checkboxContainer, ...containerStyle }}>
        <div onClick={this.onChange}>
          {value === 'true' ? (
            <CheckSquare size={26} color={theme.colors.white} />
          ) : (
            <Square size={26} color={theme.colors.white} />
          )}
        </div>
        <div style={styles.label}></div>
      </div>
    )
  }
  onChange = () => {
    // @ts-ignore
    this.props.onChange(this.props.name, this.props.value === 'true' ? 'false' : 'true')
  }
}

const styles: {
  checkboxContainer: CSSProperties
  checkbox: CSSProperties
  label: CSSProperties
  link: CSSProperties
} = {
  checkboxContainer: {
    flexDirection: 'row',
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  checkbox: {
    alignSelf: 'center',
    borderColor: theme.colors.white,
  },
  label: {
    marginLeft: 8,
    marginRight: 10,
  },
  link: { color: theme.colors.primary.regular, textDecoration: 'none' },
}
