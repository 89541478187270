import React from 'react'
import { IStore } from '../../../store'
import { connect } from 'react-redux'
import Button from '../../../components/Button'
import { ChevronLeft } from 'react-feather'
import { IAdminCohortObject } from '../../../store/admin/reducer'
import { ADMINCOHORT_FETCH, ADMIN_DELETE_PARTICIPATE, ADMIN_DELETE_RESSOURCE } from '../../../store/admin/constants'
import moment from 'moment'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import AddForm from './add'

interface IProps {
  match: any
  requesting: boolean
  dispatch: (object: { type: string; payload?: any }) => void
  navigation: any
  history: any
}
interface IState {}
class CohortAdmin extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {}
  }
  public render(): JSX.Element | null {
    const { history, requesting } = this.props
    const {} = this.state
    return (
      <div style={{ position: 'relative' }}>
        <div className="adminList__dataContainer">
          <div className="adminList__buttonContainer">
            <Button onClick={this.returnList} theme="greyLight" style={{ marginRight: 20 }}>
              <ChevronLeft size={24} />
              Retour
            </Button>
          </div>

          <AddForm goToCohort={this.goToCohort} />
        </div>
      </div>
    )
  }
  private returnList = () => {
    this.props.history.push('/online/admin/')
  }
  private goToCohort = (cohortId: number) => {
    this.props.history.push('/online/admin/cohort/' + cohortId + '/')
  }
}

export default connect((store: IStore, props: IProps) => {
  return {
    error: store.admin.cohort.error,
    requesting: store.admin.cohort.requesting,
  }
})(CohortAdmin)
