import React, { Component, CSSProperties } from 'react'
import theme from '../../theme'

interface IProps {
  value: string
  onChange: (name: string, value: string) => void
  name: string
  secureTextEntry?: boolean
  style?: CSSProperties
  containerStyle?: CSSProperties
  placeholder?: string
}
interface IState {
  selectedFile: any
}
export default class CsvFile extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { selectedFile: null }
  }

  // On file select (from the pop up)
  onFileChange = (event: any) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] }, () => {
      this.props.onChange(this.props.name, event.target.files[0])
    })
  }

  // On file upload (click the upload button)
  onFileUpload = () => {
    // Create an object of formData
    /* const formData = new FormData()

    // Update the formData object
    formData.append('myFile', this.state.selectedFile, this.state.selectedFile.name)

    // Details of the uploaded file
    console.log(this.state.selectedFile)

    // Request made to the backend api
    // Send formData object
    //axios.post('api/uploadfile', formData)*/
  }

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <span style={{ ...styles.input, ...this.props.style }}>CSV: {this.state.selectedFile.name}</span>
        </div>
      )
    } else {
      return (
        <div>
          <span style={{ ...styles.input, ...this.props.style }}>Choisir un CSV</span>
        </div>
      )
    }
  }

  render() {
    return (
      <div
        style={{
          ...styles.container,
          ...this.props.containerStyle,
          ...{ flex: 1, justifyContent: 'center', alignItems: 'center' },
        }}
      >
        {this.fileData()}
        <div>
          <input type="file" accept=".csv" onChange={this.onFileChange} />
        </div>
      </div>
    )
  }
}

const styles: { container: CSSProperties; input: CSSProperties; icon: CSSProperties } = {
  container: {
    height: '48px',
    background: '#FFFFFF',
    borderRadius: '24px',
    paddingRight: '20px',
    paddingLeft: '20px',
    flexDirection: 'row',
    display: 'flex',
  },
  input: {
    flex: '1 1 auto',
    marginRight: '8px',
    color: theme.colors.greys.regular,
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '15px',
    lineHeight: '18px',
    outline: '0 !important',
  },
  icon: {
    padding: '5px',
    cursor: 'pointer',
  },
}
